import { Flex, Title, Text, Anchor, Divider } from '@mantine/core'

const NorthernTerritory = () => {
  return (
    <>
      <Flex
        direction='column'
        gap={25}
        px={8}
      >
        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Companion Card</Title>
          <Text fz='sm'>
            <p>If you have a disability and need a lifelong attendant care support to access the community, you may be eligible for a Companion Card.</p>
            <strong>Phone:</strong> <Anchor href='tel:0889485400'>08 8948 5400</Anchor><br />
            <strong>Email:</strong> <Anchor href='mailto:info@ntcompanioncard.org.au'>info@ntcompanioncard.org.au</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://ntcompanioncard.org.au/' target='_blank'>NT Companion Card</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />
        
        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Disability Parking Permits</Title>
          <Text fz='sm'>
            <p>A Disability Parking Permit is available to people with mobility limitations and those who require special parking consideration.</p>
            <strong>Phone:</strong> <Anchor href='tel:0889300603'>08 8930 0603</Anchor><br />
            <strong>Email:</strong> <Anchor href='mailto:darwin@darwin.nt.gov.au'>darwin@darwin.nt.gov.au</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.darwin.nt.gov.au/resident-services/parking/parking-permits/disability-parking-permits' target='_blank'>Disability Parking Permits</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Parentline</Title>
          <Text fz='sm'>
            <p>Provides free counselling and support for parents and carers in Queensland and the Northern Territory.</p>
            <strong>Phone:</strong> <Anchor href='tel:1300301300'>1300 301 300</Anchor><br />
            <strong>Email:</strong> <Anchor href='mailto:counsellor@parentline.com.au'>counsellor@parentline.com.au</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://parentline.com.au/' target='_blank'>Parentline</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Territory FACES</Title>
          <Text fz='sm'>
            <p>FACES (Family and Children Enquiry & Support) is a statewide hotline for information and referrals to support services.</p>
            <strong>Phone:</strong> <Anchor href='tel:1800999900'>1800 999 900</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://tfhc.nt.gov.au/children-and-families/territory-faces' target='_blank'>Territory FACES</Anchor>
          </Text>
        </Flex>
      </Flex>
    </>
  )
}

export default NorthernTerritory