import { Helmet } from 'react-helmet' 
import { Flex, Text, Space, Title } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

import Header from '../components/Header'
import Footer from '../components/Footer'
import PageHeading from '../components/PageHeading'
import SupportForm from '../components/SupportForm'

const SupportCoordination = () => {
  const isMaxWidth = useMediaQuery('(min-width: 800px)')
  const isMobile = useMediaQuery('(max-width: 800px)')

  return (
    <>
      <Helmet>
        <title>Support Coordination | More About the Journey</title>
        <meta name='description' content='At More About the Journey, we support NDIS participants to get the most out of their NDIS plans.' />

        <meta property='og:title' content='Support Coordination' />
        <meta property='og:description' content='At More About the Journey, we support NDIS participants to get more out of their NDIS plans.' />
        <meta property='og:url' content='https://moreaboutthejourney.com.au/services/support-coordination' />

        <meta name='twitter:title' content='Support Coordination | More About the Journey' />
        <meta name='twitter:description' content='At More About the Journey, we support NDIS participants to get the most out of their NDIS plans.' />
      </Helmet>

      <Header />
      <PageHeading title='Support Coordination' />
      <Flex
        direction='column'
        justify='left'
        w={ isMaxWidth ? 800 : '90vw' }
        mx='auto'
        mt='xl'
        mb={80}
      >
        <Text fz={ isMobile ? 'sm' : 'md' }>
          NDIS Support Coordination is a service that supports NDIS participants to get the most out of their NDIS plans. We give you choice and control over the supports and services you access. We work closely with you and your family or carers to find the most effective way to achieve the goals in your NDIS plan.
        </Text>
        <Space h={20} />
        <Text fz={ isMobile ? 'sm' : 'md' }>
          We are a team of Support Coordinators with strong experience in the disability sector, disability support services, and in in-depth understanding of the NDIS. Our passion is social inclusion for people with disability, and this goal drives our work.
        </Text>
        <Space h={20} />
        <Text fz={ isMobile ? 'sm' : 'md' }>
          <Title fz='lg' fw={500} color='brand-lightblue'>Fees & Pricing</Title>
          <ul>
            <li>There are no hidden or ongoing fees for our Support Coordination services.</li>
            <li>We only charge for the time it takes to service the needs of our clients, and nothing more.</li>
            <li>There is no charge for an initial meet and greet. These meetings are about getting to know you and your family or carers, to understand what you are looking for and how we can help you.</li>
          </ul>
        </Text>
        <SupportForm />
      </Flex>
      <Footer />
    </>
  )
}

export default SupportCoordination