import { Helmet } from 'react-helmet'
import { Flex, Text, SimpleGrid } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { Files, Users, UsersThree, Heartbeat } from '@phosphor-icons/react'

import Header from '../components/Header'
import Footer from '../components/Footer'
import PageHeading from '../components/PageHeading'
import ServiceBox from '../components/ServiceBox'

const Services = () => {
  const isMaxWidth = useMediaQuery('(min-width: 1000px)')
  const isMobile = useMediaQuery('(max-width: 620px)')

  return (
    <>
      <Helmet>
        <title>Services | More About the Journey</title>
        <meta name='description' content='We have a variety of services available that can help you at whatever step of the journey you are currently embarking on.' />

        <meta property='og:title' content='Services' />
        <meta property='og:description' content='We have a variety of services available that cam help you at whatever step of the journey you are currently embarking on.' />
        <meta property='og:url' content='https://moreaboutthejourney.com.au/services' />

        <meta name='twitter:title' content='Services | More About the Journey' />
        <meta name='twitter:description' content='We have a variety of services available that can help you at whatever step of the journey you are currently embarking on.' />
      </Helmet>

      <Header />
      <PageHeading title='Services' />
      <Flex
        direction='column'
        justify='left'
        w={ isMaxWidth ? 1000 : '90vw' }
        mx='auto'
        mt='xl'
        mb={80}
      >
        <Text fz={isMobile ? 'sm' : 'md'} mx={20} mb='xl'>
          We have a variety of services available that can help you at whatever step of the journey you're currently embarking on. Please click the links below to learn more.
        </Text>
        <SimpleGrid cols={ isMobile ? 1 : 2 } spacing='xl'>
          <ServiceBox
            title='Access Applications'
            link='access-applications'
            description="We can help walk you through the application process, and answer any questions you might have."
            icon={ <Files size={150} weight='duotone' color='#3ABADF' /> }
          />
          <ServiceBox
            title='Support Coordination'
            link='support-coordination'
            description="We work closely with NDIS participants and their families and carers to get the most out of their NDIS plan."
            icon={ <Users size={150} weight='duotone' color='#3ABADF' /> }
          />
          <ServiceBox
            title='Specialist Support Coordination'
            link='specialist-support-coordination'
            description="We work with NDIS participants dealing with high-level risks in their current situation."
            icon={ <UsersThree size={150} weight='duotone' color='#3ABADF' /> }
          />
          <ServiceBox
            title='Psychosocial Recovery Coaching'
            link='psychosocial-recovery-coaching'
            description="We can help you work through life's challenges, and assist you in making the right choices for you."
            icon={ <Heartbeat size={150} weight='duotone' color='#3ABADF' /> }
          />
        </SimpleGrid>
      </Flex>
      <Footer />
    </>
  )
}

export default Services