import { Helmet } from 'react-helmet'
import { Flex, Grid, Text, Anchor, Space } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { FaLinkedin, FaFacebookSquare, FaInstagram } from 'react-icons/fa'

import Header from '../components/Header'
import Footer from '../components/Footer'
import PageHeading from '../components/PageHeading'
import ContactForm from '../components/ContactForm'

const Contact = () => {
  const isMaxWidth = useMediaQuery('(min-width: 1000px)')
  const isTablet = useMediaQuery('(max-width: 800px)')
  const isMobile = useMediaQuery('(max-width: 550px)')

  return (
    <>
      <Helmet>
        <title>Contact Us | More About the Journey</title>
        <meta name='description' content='Get in touch with the team at More About the Journey, and we can help you get the support you need.' />

        <meta property='og:title' content='Contact Us' />
        <meta property='og:description' content='Get in touch with the team at More About the Journey, and we can help you get the support you need.' />
        <meta property='og:url' content='https://moreaboutthejourney.com.au/contact' />

        <meta name='twitter:title' content='Contact Us | More About the Journey' />
        <meta name='twitter:description' content='Get in touch with the team at More About the Journey, and we can help you get the support you need.' />
      </Helmet>

      <Header />
      <PageHeading title='Contact Us' />
      <Flex
        direction='column'
        justify='left'
        w={ isMaxWidth ? 1000 : '90vw' }
        mx='auto'
        mt='xl'
        mb={80}
      >
        <Grid gutter='xl'>
          <Grid.Col span={isTablet ? (isMobile ? 12 : 6) : 6}>
            <Flex direction='column' >
              <Text fz={ isMobile ? 'sm' : 'md' }>
                To get in touch, please complete the form, and we'll get back to you as soon as possible. If you want to <strong>provide feedback or lodge a complaint</strong>, please use the form found <Anchor href='/feedback'>here</Anchor>.
                </Text>
              <Space h={20} />
              <Text fz={ isMobile ? 'sm' : 'md' }>
                If you prefer to talk to someone, feel free to give us a call at <strong>03 7042 1059</strong>.
                </Text>
              <Space h={20} />
              <Text fz={ isMobile ? 'sm' : 'md' }>
                You can find us located at <strong>3/98 Nixon Street, Shepparton VIC 3630</strong>.
                </Text>
              <Space h={20} />
              <Text fz={ isMobile ? 'sm' : 'md' }>
                We're in the office <strong>Monday to Friday, from 8am until 5pm</strong>.
                </Text>
              <Text fz={ isMobile ? 'sm' : 'md' } mt={20}>
                <Anchor href='https://linkedin.com/company/moreaboutthejourney' target='_blank' color='brand-teal.4'><FaLinkedin size={36} /></Anchor>
                <Anchor href='https://facebook.com/moreaboutthejourney' target='_blank' mx={4} color='brand-teal.4'><FaFacebookSquare size={36} /></Anchor>
                <Anchor href='https://instagram.com/moreaboutthejourney_' target='_blank' color='brand-teal.4'><FaInstagram size={36} /></Anchor>
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={isTablet ? (isMobile ? 12 : 6) : 6}>
            <ContactForm />
          </Grid.Col>
        </Grid>
      </Flex>
      <Footer />
    </>
  )
}

export default Contact