import { Flex, Image, Modal, Button } from '@mantine/core'
import { useMediaQuery, useDisclosure } from '@mantine/hooks'
import { NavLink } from 'react-router-dom'
import { List } from '@phosphor-icons/react'
import { NavLinks } from '../theme'

import Logo from '../assets/images/logo.svg'

const Header = () => {
  const isMaxWidth = useMediaQuery('(min-width: 1000px)')
  const isMobile = useMediaQuery('(max-width: 650px)')
  const [opened, { open, close }] = useDisclosure(false)

  const desktop = (
    <Flex
      justify='space-around'
      align='center'
      my='lg'
      w={ isMaxWidth ? 1000 : '95vw' }
      mx='auto'
    >
      <NavLink to='/' title='Home page'><Image src={Logo} alt='More About the Journey Logo' width={240} /></NavLink>
      <Flex gap='xl'>
        <NavLinks>
          <NavLink to='/' title='Home page'>Home</NavLink>
          <NavLink to='/services' title='View our services'>Services</NavLink>
          <NavLink to='/about' title='About us'>About</NavLink>
          <NavLink to='/contact' title='Contact us'>Contact</NavLink>
        </NavLinks>
      </Flex>
    </Flex>
  )

  const mobile = (
    <Flex
      justify='space-between'
      align='center'
      my='lg'
      ml={40}
      mr={20}
    >
      <NavLink to='/' title='Home page'><Image src={Logo} alt='More About the Journey Logo' width={140} /></NavLink>
      <Modal
        opened={opened}
        onClose={close}
        fullScreen
        title={<></>}
      >
        <Image
          src={Logo}
          alt='More About the Journey Logo'
          width='60vw'
          mx='auto'
          pb={40}
        />
        <NavLinks>
          <Flex direction='column' align='center' mx='auto' gap='xl'>
            <NavLink to='/' title='Home page'>Home</NavLink>
            <NavLink to='/services' title='View our services'>Services</NavLink>
            <NavLink to='/about' title='About us'>About</NavLink>
            <NavLink to='/contact' title='Contact us'>Contact</NavLink>
          </Flex>
        </NavLinks>
      </Modal>
      <Button
        onClick={open}
        leftIcon={<List size={20} />}
        variant='white'
        color='dark.5'
      >
        Menu
      </Button>
    </Flex>
  )

  return (
    <>
      { isMobile ? mobile : desktop }
    </>
  )
}

export default Header