import styled from 'styled-components'

import ForestPath from './assets/images/forest-path.jpg'

const Montserrat = 'Montserrat, "Helvetica Neue", Arial, sans-serif'

const teal = '#59C5CD'
const lightblue = '#4EC6E8'
const blue = '#299DD8'
const darkblue = '#3853A3'
const purple = '#75509F'

const NavLinks = styled.div`
  display: flex;
  gap: 30px;

  a {
    font-family: ${Montserrat};
    font-weight: 500;
    color: black;
    text-decoration: none;
  }

  a:hover {
    border-bottom: 3px solid ${teal};
    margin-bottom: -3px;
  }

  .active {
    border-bottom: 3px solid ${blue};
    margin-bottom: -3px;
  }

  @media (max-width: 600px) {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      font-size: 32px;
      text-align: center;
    }

    a:hover {
      border-bottom: 5px solid ${teal};
      margin-bottom: -5px;
    }

    .active {
      border-bottom: 5px solid ${blue};
      margin-bottom: -5px;
    }
  }
`

const HeroImage = styled.div`
  width: 100vw;
  height: 500px;
  background-image: url(${ForestPath});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);

  @media (max-width: 525px) {
    height: 250px;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
`

const Quote = styled.blockquote`
  font-family: Georgia, 'Times New Roman', serif;
  font-size: 36px;
  color: ${teal};
  font-style: italic;
  text-align: center;
  margin: 90px auto 0 auto;

  &::before {
    content: '“';
    font-family: 'Times New Roman';
    font-size: 44px;
  }

  &::after {
    content: '”';
    font-family: 'Times New Roman';
    font-size: 44px;
  }
`

const FooterContainer = styled.div`
  width: 100%;
  background-color: ${darkblue};
  padding: 20px 0;

  .newsletter {
    height: 52px;
    border: 0;
  }
`

export {
  NavLinks,
  HeroImage,
  Quote,
  FooterContainer
}