import { Flex, Title, Text, Anchor, Divider } from '@mantine/core'

const Victoria = () => {
  return (
    <>
      <Flex
        direction='column'
        gap={25}
        px={8}
      >
        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Accessible Parking</Title>
          <Text fz='sm'>
            <p>If you have a significant disability or injury, you may be eligible for an Accessibility Parking Permit.</p>
            <strong>Phone:</strong> <Anchor href='tel:1300965677'>1300 965 677</Anchor><br />
            <strong>Email:</strong> <Anchor href='mailto:accessibleparking@roads.vic.gov.au'>accessibleparking@roads.vic.gov.au</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.vicroads.vic.gov.au/safety-and-road-rules/road-rules/disability-parking' target='_blank'>Accessible Parking Permits</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Association for Children with a Disability</Title>
          <Text fz='sm'>
            <p>Advocating for children with disability and their families living in Victoria.</p>
            <strong>Phone:</strong> <Anchor href='tel:1300965677'>03 9880 7000</Anchor> (Melbourne) or <Anchor href='tel:1800654013'>1800 654 013</Anchor> (regional)<br />
            <strong>Email:</strong> <Anchor href='mailto:mail@acd.org.au'>mail@acd.org.au</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.acd.org.au/' target='_blank'>Association for Children with a Disability</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Brain Injury Matters</Title>
          <Text fz='sm'>
            <p>Self-advocacy and community education organisation run by and for people with Acquired Brain Injury (ABI).</p>
            <strong>Phone:</strong> <Anchor href='tel:0396397222'>03 9639 7222</Anchor><br />
            <strong>Email:</strong> <Anchor href='mailto:office@braininjurymatters.org'>office@braininjurymatters.org</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.braininjurymatters.org/' target='_blank'>Brain Injury Matters</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Companion Card</Title>
          <Text fz='sm'>
            <p>A Companion Card is for people with a significant and permanent disability who have a lifelong need for a high level of support to participate in community events and activities.</p>
            <strong>Phone:</strong> <Anchor href='tel:1800650611'>1800 650 611</Anchor><br />
            <strong>Email:</strong> <Anchor href='mailto:companioncard@dffh.vic.gov.au'>companioncard@dffh.vic.gov.au</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.companioncard.vic.gov.au/' target='_blank'>Companion Card</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Council of Single Mothers and their Children</Title>
          <Text fz='sm'>
            <p>Provides essential support and assistance to single mothers and their families.</p>
            <strong>Phone:</strong> <Anchor href='tel:0396540622'>03 9654 0622</Anchor> (Melbourne), <Anchor href='tel:1300552511'>1300 552 511</Anchor> (regional)<br />
            <strong>Email:</strong> <Anchor href='mailto:csmc@csmc.org.au'>csmc@csmc.org.au</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.csmc.org.au/' target='_blank'>Council of Single Mothers and their Children</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Deaf Victoria</Title>
          <Text fz='sm'>
            <p>Advocacy support representing deaf and hard of hearing Victorians.</p>
            <strong>Phone:</strong> <Anchor href='tel:1300780225'>1300 780 225</Anchor> (call) or <Anchor href='sms:0412217586'>0412 217 586</Anchor> (text)<br />
            <strong>Email:</strong> <Anchor href='mailto:info@deafvictoria.org.au'>info@deafvictoria.org.au</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.deafvictoria.org.au/' target='_blank'>Deaf Victoria</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Disability Resources Centre</Title>
          <Text fz='sm'>
            <p>Ensuring Victorians with disabilities enjoy the same rights and opportunities as every other member of society.</p>
            <strong>Phone:</strong> <Anchor href='tel:0396713000'>03 9671 3000</Anchor><br />
            <strong>Email:</strong> <Anchor href='mailto:advocacy@drc.org.au'>advocacy@drc.org.au</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://drc.org.au/' target='_blank'>Disability Resources Centre</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Maternal and Child Health Line</Title>
          <Text fz='sm'>
            <p>The confidential 24-hour health line can give you information and advice about the care and health of children from birth to school age.</p>
            <strong>Phone:</strong> <Anchor href='tel:132229'>13 22 29</Anchor><br />
            <strong>More information:</strong> <Anchor href='hhttps://www.betterhealth.vic.gov.au/health/serviceprofiles/maternal-and-child-health-line' target='_blank'>Maternal and Child Health Line</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Parentline</Title>
          <Text fz='sm'>
            <p>Parentline is a phone service for parents and carers of children from birth to 18 years old.</p>
            <strong>Phone:</strong> <Anchor href='tel:132289'>13 22 89</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://services.dffh.vic.gov.au/parentline' target='_blank'>Parentline</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Taxi Cards</Title>
          <Text fz='sm'>
            <p>The Multi Purpose Taxi Program assists with the travel needs of people with accessibility and mobility needs by offering subsidised commercial passenger vehicle fares to members.</p>
            <strong>Website:</strong> <Anchor href='https://cpv.vic.gov.au/passengers/mptp' target='_blank'>Commercial Passenger Vehicles Victoria</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>VALID</Title>
          <Text fz='sm'>
            <p>Offers training tools, information, resources, and advocacy to help empower people with disabilities and their families.</p>
            <strong>Phone:</strong> <Anchor href='tel:0394164003'>03 9416 4003</Anchor><br />
            <strong>Email:</strong> <Anchor href='mailto:info@valid.org.au'>info@valid.org.au</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://valid.org.au/' target='_blank'>VALID</Anchor>
          </Text>
        </Flex>
      </Flex>
    </>
  )
}

export default Victoria