import { Title, Image } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

import Dots from '../assets/images/dots.svg'

interface Props {
  title: string
}

const PageHeading = ({ title }: Props) => {
  const isMobile = useMediaQuery('(max-width: 620px)')

  return (
    <>
      <Title size={isMobile ? '32px' : '42px'} fw={600} mt={50} mb={10} ta='center' color='brand-purple'>{ title }</Title>
      <Image src={Dots} width={isMobile ? 180 : 250} mt={isMobile ? -8 : -12} mx='auto' />
    </>
  )
}

export default PageHeading