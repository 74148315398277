import { Helmet } from 'react-helmet'
import { Flex, Text, Anchor } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

import Header from '../components/Header'
import Footer from '../components/Footer'
import PageHeading from '../components/PageHeading'

const Error = () => {
  const isMaxWidth = useMediaQuery('(min-width: 1000px)')
  const isMobile = useMediaQuery('(max-width: 800px)')

  return (
    <>
      <Helmet>
        <title>Error | More About the Journey</title>
        <meta name='description' content='Oops, there is nothing here. Try visiting our home page to find what you are looking for.' />

        <meta property='og:title' content='Error' />
        <meta property='og:description' content='Oops, there is nothing here. Try visiting our home page to find what you are looking for.' />
        <meta property='og:url' content='https://moreaboutthejourney.com.au' />

        <meta name='twitter:title' content='Error | More About the Journey' />
        <meta name='twitter:description' content='Oops, there is nothing here. Try visiting our home page to find what you are looking for.' />
      </Helmet>

      <Header />
      <PageHeading title='Error' />
      <Flex
        justify='left'
        w={ isMaxWidth ? 1000 : '90vw' }
        mx='auto'
        my='xl'
        px={20}
      >
        <Text fz={ isMobile ? 'sm' : 'md' }>
          Oops, there's nothing here. Try visiting our <Anchor href='htps://moreaboutthejourney.com.au'>home page</Anchor>.
        </Text>
      </Flex>
      <Footer />
    </>
  )
}

export default Error