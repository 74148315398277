import { Flex, Title, Text, Anchor, Divider } from '@mantine/core'

const SouthAustralia = () => {
  return (
    <>
      <Flex
        direction='column'
        gap={25}
        px={8}
      >
        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Companion Card</Title>
          <Text fz='sm'>
            <p>A Companion Card is for people with significant and permanent disability. When a cardholder buys a ticket at participating venues and activities in South Australia, their companion or carer will be given free entry.</p>
            <strong>Phone:</strong> <Anchor href='tel:1800667110'>1800 667 110</Anchor><br />
            <strong>Email:</strong> <Anchor href='mailto:DHSCompanionCard@sa.gov.au'>DHSCompanionCard@sa.gov.au</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.sa.gov.au/topics/care-and-support/disability/companion-card' target='_blank'>Companion Card</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Disability Parking Permit</Title>
          <Text fz='sm'>
            <p>Disability parking permits allow a vehicle to be parked in spaces where the international symbol of access is displayed.</p>
            <strong>Website:</strong> <Anchor href='https://www.sa.gov.au/topics/driving-and-transport/disability/disability-parking-permit' target='_blank'>Disability parking permit</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Parent Helpline</Title>
          <Text fz='sm'>
            <p>The Parent Helpline provides telephone information and support for parents and carers of children from birth to five years living in South Australia.</p>
            <strong>Phone:</strong> <Anchor href='tel:1300364100'>1300 364 100</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.cafhs.sa.gov.au/services/parent-helpline' target='_blank'>Parent Helpline</Anchor>
          </Text>
        </Flex>
      </Flex>
    </>
  )
}

export default SouthAustralia