import { Flex, Image, Text, Anchor, Space } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { FaLinkedin, FaFacebookSquare, FaInstagram } from 'react-icons/fa'
import { FooterContainer } from '../theme'

import Aboriginal from '../assets/images/flag-aboriginal.png'
import TorresStrait from '../assets/images/flag-torresstrait.png'

const Footer = () => {
  const isMaxWidth = useMediaQuery('(min-width: 1000px)')
  const isMobile = useMediaQuery('(max-width: 520px)')

  return (
    <FooterContainer>
      <Flex
        direction={ isMobile ? 'column' : 'row' }
        justify='space-between'
        w={ isMaxWidth ? 1000 : '90vw' }
        mx='auto'
        p={20}
        gap={40}
      >
        <Flex
          direction='column'
          gap={8}
        >
          <Text fw={600} color='brand-teal' sx={{ fontFamily: 'Montserrat' }}>
            Acknowledgement
          </Text>
          <Text fz='xs' fw={500} color='white' sx={{ fontFamily: 'Montserrat', maxWidth: '400px' }}>
            More About the Journey acknowledges the Traditional Owners and Custodians of the land on which we work, and we pay respect to Elders past and present.
          </Text>
          <Flex
            direction='row'
            gap={8}
          >
            <Image src={Aboriginal} alt='Aboriginal flag' width='40px' />
            <Image src={TorresStrait} alt='Torres Strait Islander flag' width='40px' />
          </Flex>
        </Flex>
        <Flex
          direction='column'
          gap={6}
        >
          <Text fw={600} color='brand-teal' sx={{ fontFamily: 'Montserrat' }}>
            Useful Links
          </Text>
          <Text fz='xs' fw={500}>
            <Anchor href='https://moreaboutthejourney.com.au/resources' color='white' sx={{ fontFamily: 'Montserrat' }}>Resources</Anchor>
          </Text>
          <Text fz='xs' fw={500}>
            <Anchor href='https://moreaboutthejourney.com.au/service-agreement' color='white' sx={{ fontFamily: 'Montserrat' }}>Service Agreement</Anchor>
          </Text>
          <Text fz='xs' fw={500}>
            <Anchor href='https://moreaboutthejourney.com.au/feedback' color='white' sx={{ fontFamily: 'Montserrat' }}>Feedback</Anchor>
          </Text>
        </Flex>
        <Flex
          direction='column'
          // gap={8}
          gap={4}
        >
          <Text fw={600} color='brand-teal' sx={{ fontFamily: 'Montserrat' }}>
            Contact
          </Text>
          {/* <Text fz='xs' fw={500} color='white' sx={{ fontFamily: 'Montserrat' }}>
            3/98 Nixon Street<br />Shepparton, VIC 3630
          </Text> */}
          <Text fz='xs' fw={500} color='white' sx={{ fontFamily: 'Montserrat' }}>
            03 7042 1059
          </Text>
          <Text fz='xs' fw={500} color='white' sx={{ fontFamily: 'Montserrat' }}>
            admin@moreaboutthejourney.com.au
          </Text>
          <Text fz='xs' fw={500} color='white' sx={{ fontFamily: 'Montserrat' }}>
            Mon-Fri 8am-5pm
          </Text>
          {/* <Text fw={600} color='brand-teal' sx={{ fontFamily: 'Montserrat' }}>
            Sign Up for our Newsletter
          </Text>
          <iframe src='https://embeds.beehiiv.com/aea4bf0e-c817-4f33-bc32-f80ce5186824?slim=true' data-test-id='beehiiv-embed' className='newsletter'></iframe> */}
          <Space h={10} />
          <Text>
            <Anchor href='https://linkedin.com/company/moreaboutthejourney' target='_blank' color='brand-teal.4'><FaLinkedin size={32} /></Anchor>
            <Anchor href='https://facebook.com/moreaboutthejourney' target='_blank' mx={6} color='brand-teal.4'><FaFacebookSquare size={32} /></Anchor>
            <Anchor href='https://instagram.com/moreaboutthejourney_' target='_blank' color='brand-teal.4'><FaInstagram size={32} /></Anchor>
          </Text>
        </Flex>
      </Flex>
      <Flex
        justify='space-between'
        w={ isMaxWidth ? 1000 : '90vw' }
        mx='auto'
        pb={20}
      >
        <Text color='brand-darkblue.2' fz='small' sx={{ fontFamily: 'Montserrat' }}>
          &copy; 2023 More About the Journey
        </Text>
      </Flex>
    </FooterContainer>
  )
}

export default Footer