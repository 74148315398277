import { Title, Flex, Paper, Text } from '@mantine/core'
import { useMediaQuery, useHover } from '@mantine/hooks'
import { ArrowCircleRight } from '@phosphor-icons/react'

interface Props {
  title: string,
  link: string,
  description: string,
  icon: JSX.Element
}

const ServiceBox = ({ title, link, description, icon }: Props) => {
  const isMobile = useMediaQuery('(max-width: 620px)')
  const { hovered, ref } = useHover()

  return (
    <Paper shadow='sm' radius='md' p='md' component='a' href={'/services/' + link }>
      <Flex direction='row' gap='lg' ref={ref}>
        { icon }
        <Flex direction='column' justify='center'>
          <Title fz={isMobile ? 'xl' : 24} color={hovered ? 'brand-purple' : 'brand-blue'}>{ title } <ArrowCircleRight size={isMobile ? 16 : 20} weight='duotone' /></Title>
          <Text fz={isMobile ? 'sm' : 'md'} my={15}>{ description }</Text>
        </Flex>
      </Flex>
    </Paper>
  )
}

export default ServiceBox