import { Helmet } from 'react-helmet'
import Header from '../components/Header'
import Hero from '../components/Hero'
import OurServices from '../components/OurServices'
import AboutUs from '../components/AboutUs'
import Testimonials from '../components/Testimonials'
import Footer from '../components/Footer'

const Home = () => {
  return (
    <>
      <Helmet>
        <title>More About the Journey</title>
        <meta name='description' content='We support you on your journey and offer support and assistance for people living with disability.' />

        <meta property='og:title' content='More About the Journey' />
        <meta property='og:description' content='We support you on your journey and offer support and assistance for people living with disability.' />
        <meta property='og:url' content='https://moreaboutthejourney.com.au' />

        <meta name='twitter:title' content='More About the Journey' />
        <meta name='twitter:description' content='We support you on your journey and offer support and assistance for people living with disability.' />
      </Helmet>

      <Header />
      <Hero />
      <OurServices />
      <AboutUs />
      <Testimonials />
      <Footer />
    </>
  )
}

export default Home