import { Helmet } from 'react-helmet'
import { Flex, Text, Space } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

import Header from '../components/Header'
import Footer from '../components/Footer'
import PageHeading from '../components/PageHeading'

const PsychosocialRecoveryCoaching = () => {
  const isMaxWidth = useMediaQuery('(min-width: 800px)')
  const isMobile = useMediaQuery('(max-width: 800px)')

  return (
    <>
      <Helmet>
        <title>Psychosocial Recovery Coaching | More About the Journey</title>
        <meta name='description' content='At More About the Journey we help NDIS participants make quality and positive life choices to improve their mental health and everyday lives.' />

        <meta property='og:title' content='Psychosocial Recovery Coaching' />
        <meta property='og:description' content='At More About the Journey we help NDOS participants make quality and positive life choices to improve their mental health and everyday lives.' />
        <meta property='og:url' content='https://moreaboutthejourney.com.au/services/psychosocial-recovery-coaching' />

        <meta name='twitter:title' content='Psychosocial Recovery Coaching | More About the Journey' />
        <meta name='twitter:description' content='At More ABout the Journey we help NDIS participants make quality and positive life choices to improve their mental health and everyday lives.' />
      </Helmet>

      <Header />
      <PageHeading title='Psychosocial Recovery Coaching' />
      <Flex
        direction='column'
        justify='left'
        w={ isMaxWidth ? 800 : '90vw' }
        mx='auto'
        mt='xl'
        mb={80}
      >
        <Text fz={ isMobile ? 'sm' : 'md' } fw='bold' color='brand-darkblue'>
          Psychosocial recovery coaches believe that you are the expert in your own life, and they will encourage you to make the right choices.
        </Text>
        <Space h={20} />
        <Text fz={ isMobile ? 'sm' : 'md' }>
          An NDIS psychosocial recovery coach is a qualified individual who helps those with psychological disabilities. They are sometimes referred to as mental health peer workers, NDIS life coaches, or life coaches. These coaches give the time, attention and respect to those who struggle to live resourcefully due to mental health issues.
        </Text>
        <Space h={20} />
        <Text fz={ isMobile ? 'sm' : 'md' }>
          Additionally, a recovery coach's main focus is to improve the mental health of their participants. They help those who don't have the ability to plan tasks in their everyday life.
        </Text>
        <Space h={20} />
        <Text fz={ isMobile ? 'sm' : 'md' }>
          The recovery coach may work with an individual for as many hours as necessary.
        </Text>
        <Space h={20} />
        <Text fz={ isMobile ? 'sm' : 'md' }>
          This time is used by the coach to help a person develop a Recovery Plan, and help break down their NDIS plan into achievable goals. These plans may help people with disabilities exercise their choice and control their goals, to achieve a more fulfilling life.
        </Text>
        <Space h={20} />
        <Text fz={ isMobile ? 'sm' : 'md' }>
          Even if the person living with a disability feels disconnected from their community, the recovery coach can assist them with plans to be more active and engaged. Furthermore, a recovery coach can help you with various life skills to improve your way of living.
        </Text>
      </Flex>
      <Footer />
    </>
  )
}

export default PsychosocialRecoveryCoaching