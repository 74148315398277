import { Flex, Title, Text, Anchor, Divider } from '@mantine/core'

const Australia = () => {
  return (
    <>
      <Flex
        direction='column'
        gap={25}
        px={8}
      >
        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>1800RESPECT</Title>
          <Text fz='sm'>
            <p>1800RESPECT provides free, 24-hour support ro people impacted by domestic, family or sexual violence.</p>
            <strong>Phone:</strong> <Anchor href='tel:1800737732'>1800 737 732</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.1800respect.org.au/' target='_blank'>1800RESPECT</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />
        
        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Beyond Blue</Title>
          <Text fz='sm'>
            <p>Supporting Australians for more than 20 years with mental health information, support, and hope.</p>
            <strong>Phone:</strong> <Anchor href='tel:1300224636'>1300 224 636</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.beyondblue.org.au/' target='_blank'>Beyond Blue</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />
        
        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Blind Citizens Australia</Title>
          <Text fz='sm'>
            <p>Inofrming, connecting, and empowering Australians who are blind or vision impaired and the broader community.</p>
            <strong>Phone:</strong> <Anchor href='tel:1800033660'>1800 033 660</Anchor> (call) or <Anchor href='sms:0436446780'>0436 446 780</Anchor> (text)<br />
            <strong>Email:</strong> <Anchor href='mailto:bca@bca.org.au'>bca@bca.org.au</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.bca.org.au/' target='_blank'>Blind Citizens Australia</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />
        
        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Blue Knot Foundation</Title>
          <Text fz='sm'>
            <p>Provides information and support for those living with the effects of abuse, neglect, or violence.</p>
            <strong>Phone:</strong> <Anchor href='tel:1300657380'>1300 657 380</Anchor><br />
            <strong>Email:</strong> <Anchor href='mailto:helpline@blueknot.org.au'>helpline@blueknot.org.au</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://blueknot.org.au/' target='_blank'>Blue Knot Foundation</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />
        
        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Carer Gateway</Title>
          <Text fz='sm'>
            <p>Carers can get a wide range of help, from counselling and peer support groups, to respite care, home help, and equipment.</p>
            <strong>Phone:</strong> <Anchor href='tel:1800422737'>1800 422 737</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.carergateway.gov.au/' target='_blank'>Carer Gateway</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />
        
        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Carer Payment and Carer Allowance</Title>
          <Text fz='sm'>
            <p>Carers who provide full time care may be eligible for the fortnightly Carer Payment to help them meet their basic costs of living.</p>
            <strong>Phone:</strong> <Anchor href='tel:0261229900'>02 6122 9900</Anchor><br />
            <strong>Email:</strong> <Anchor href='mailto:caa@carersaustralia.com.au'>caa@carersaustralia.com.au</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.carersaustralia.com.au/support-for-carers/centrelink-payments-for-carers/' target='_blank'>Centrelink Payments for Carers</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />
        
        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Chronic Disease Management Plan</Title>
          <Text fz='sm'>
            <p>A chronic disease management plan is for people who live with a chronic illness. You can get a chronic disease management plan from your usual GP.</p>
            <strong>Phone:</strong> <Anchor href='tel:1800022222'>1800 022 222</Anchor><br />
            <strong>More information:</strong> <Anchor href='https://www.healthdirect.gov.au/management-of-chronic-conditions' target='_blank'>Management of chronic conditions</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />
        
        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Communication Rights Australia</Title>
          <Text fz='sm'>
            <p>Australia's leading information and advocacy service for people with high communication support needs.</p>
            <strong>Phone:</strong> <Anchor href='tel:0395558552'>03 9555 8552</Anchor> or <Anchor href='tel:1300666604'>1300 666 604</Anchor> (toll free)<br />
            <strong>Email:</strong> <Anchor href='mailto:info@caus.com.au'>info@caus.com.au</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.communicationrights.org.au/' target='_blank'>Communication Rights Australia</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />
        
        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Continence Aids Payment</Title>
          <Text fz='sm'>
            <p>The Continence Aids Payment Scheme (CAPS) is an Australian Government program that provides a payment to eligible people to help with some of the costs of buying continence products.</p>
            <strong>Phone:</strong> <Anchor href='tel:1800239309'>1800 239 309</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.health.gov.au/our-work/continence-aids-payment-scheme-caps' target='_blank'>Continence Aids Payment Scheme</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />
        
        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Department of Veterans' Affairs</Title>
          <Text fz='sm'>
            <p>Support for those who served in defence of our nation, as well as their families and care givers.</p>
            <strong>Phone:</strong> <Anchor href='tel:1800838372'>1800 838 372</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.dva.gov.au/' target='_blank'>Department of Veterans' Affairs</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />
        
        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Disability Gateway</Title>
          <Text fz='sm'>
            <p>The Disability Gateway has information and services to help people with disability, their family, friends and carers, to find support they need in Australia.</p>
            <strong>Phone:</strong> <Anchor href='tel:1800643787'>1800 643 787</Anchor><br />
            <strong>Email:</strong> <Anchor href='mailto:disabilitygateway@benevolent.org.au'>disabilitygateway@benevolent.org.au</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.disabilitygateway.gov.au/' target='_blank'>Disability Gateway</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Disability Justice Australia</Title>
          <Text fz='sm'>
            <p>Registered charity that provides advocacy to people who have ongoing support needs as a result of disability.</p>
            <strong>Phone:</strong> <Anchor href='tel:1800808126'>1800 808 126</Anchor><br />
            <strong>Email:</strong> <Anchor href='mailto:info@dja.org.au'>info@dja.org.au</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://dja.org.au/' target='_blank'>Disability Justice Australia</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />
        
        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>GROW Australia</Title>
          <Text fz='sm'>
            <p>Offers mental health wellbeing programs and support groups, both in person and online.</p>
            <strong>Phone:</strong> <Anchor href='tel:1800558268'>1800 558 268</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://grow.org.au/' target='_blank'>GROW Australia</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />
        
        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Head to Health</Title>
          <Text fz='sm'>
            <p>Assists all Australians in accessing mental health and wellbeing services..</p>
            <strong>Phone:</strong> <Anchor href='tel:1800595212'>1800 595 212</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.headtohealth.gov.au/' target='_blank'>Head to Health</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />
        
        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Job Access</Title>
          <Text fz='sm'>
            <p>Job Access is the national hub for workplace and employment information for people with disability, employers and service providers.</p>
            <strong>Phone:</strong> <Anchor href='tel:1800464800'>1800 464 800</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.jobaccess.gov.au/' target='_blank'>Job Access</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Lifeline Australia</Title>
          <Text fz='sm'>
            <p>Providing all Australians experiencing emotional distress with access to 24-hour crisis support and suicide prevention services.</p>
            <strong>Phone:</strong> <Anchor href='tel:131114'>13 11 14</Anchor> (call) or <Anchor href='sms:0477131114'>0477 131 114</Anchor> (text)<br />
            <strong>Website:</strong> <Anchor href='https://www.lifeline.org.au/' target='_blank'>Lifeline</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Mental Health Treatment Plan</Title>
          <Text fz='sm'>
            <p>A mental health treatment plan is for people with a mental illness who have several professionals working with them. You can get a mental health treatment plan from your usual GP, who will help you put together the necessary information.</p>
            <strong>More information:</strong> <Anchor href='https://www.betterhealth.vic.gov.au/health/conditionsandtreatments/mental-health-treatment-plans' target='_blank'>Mental health treatment plans</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>My Aged Care</Title>
          <Text fz='sm'>
            <p>Offers support to the elderly, as well as their families and care givers.</p>
            <strong>Phone:</strong> <Anchor href='tel:1800200422'>1800 200 422</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.myagedcare.gov.au/' target='_blank'>My Aged Care</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>New Access Mental Health Coaching</Title>
          <Text fz='sm'>
            <p>NewAccess Mental Health Coaching is a guided 6-session self-help program developed by Beyond Blue. It's designed to give you the skills to manage everyday life stresses such as work, study, relationships, health, and loneliness.</p>
            <strong>Phone:</strong> <Anchor href='tel:0398106100'>03 9810 6100</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.beyondblue.org.au/get-support/newaccess-mental-health-coaching' target='_blank'>NewAccess</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Positive Partnerships</Title>
          <Text fz='sm'>
            <p>Working together with parents, carers and educators of school-aged children on the autism spectrum to provide current, relevant and evidence informed information through workshops and online resources.</p>
            <strong>Phone:</strong> <Anchor href='tel:1300881971'>1300 881 971</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.positivepartnerships.com.au/' target='_blank'>Positive Partnerships</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Raising Children Network</Title>
          <Text fz='sm'>
            <p>Raising Children is the Australian parenting website supported by the Australian government.</p>
            <strong>Website:</strong> <Anchor href='https://raisingchildren.net.au/' target='_blank'>Raising Children Network</Anchor>
          </Text>
        </Flex>
      </Flex>
    </>
  )
}

export default Australia