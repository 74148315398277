import { Flex, Title, Text, Anchor, Divider } from '@mantine/core'

const WesternAustralia = () => {
  return (
    <>
      <Flex
        direction='column'
        gap={25}
        px={8}
      >
        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>ACROD Parking Program</Title>
          <Text fz='sm'>
            <p>Entitles holders to use ACROD parking bays including on-stareet parking, in shopping centres, hospitals, libraries and schools.</p>
            <strong>Phone:</strong> <Anchor href='tel:0892425544'>08 9242 5544</Anchor><br />
            <strong>Email:</strong> <Anchor href='mailto:acrod@nds.org.au'>acrod@nds.org.au</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://acrod.org.au/' target='_blank'>ACROD Parking Program</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Companion Card</Title>
          <Text fz='sm'>
            <p>Supports those with a significant and permanent disability who require attendant care support to participate at community venues and events.</p>
            <strong>Phone:</strong> <Anchor href='tel:1800617337'>1800 617 337</Anchor><br />
            <strong>Email:</strong> <Anchor href='mailto:wacompanioncard@nds.org.au'>wacompanioncard@nds.org.au</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.wacompanioncard.org.au/' target='_blank'>Companion Card WA</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Ngala Parenting Line</Title>
          <Text fz='sm'>
            <p>A free support service for WA parents and caregivers of children from conception to 18 years.</p>
            <strong>Phone:</strong> <Anchor href='tel:0893689368'>08 9368 9368</Anchor> (Perth) or <Anchor href='tel:1800111546'>1800 111 546</Anchor> (regional landline)<br />
            <strong>Email:</strong> <Anchor href='mailto:ngala@ngala.com.au'>ngala@ngala.com.au</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.ngala.com.au/' target='_blank'>Ngala</Anchor>
          </Text>
        </Flex>
      </Flex>
    </>
  )
}

export default WesternAustralia