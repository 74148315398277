import { Helmet } from 'react-helmet'
import { Flex, Text, Space } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

import Header from '../components/Header'
import Footer from '../components/Footer'
import PageHeading from '../components/PageHeading'
import SupportForm from '../components/SupportForm'

const SpecialistSupportCoordination = () => {
  const isMaxWidth = useMediaQuery('(min-width: 800px)')
  const isMobile = useMediaQuery('(max-width: 800px)')

  return (
    <>
      <Helmet>
        <title>Specialist Support Coordination | More About the Journey</title>
        <meta name='description' content='Our Specialist Support Coordinators work within an NDIS specialist framework, in order to provide quality support for participants in high risk situations.' />

        <meta property='og:title' content='Specialist Support Coordination' />
        <meta property='og:description' content='Our Specialist Support Coordinators work within an NDIS specialist framework, in order to provide quality support for participants in high risk situations.' />
        <meta property='og:url' content='https://moreaboutthejourney.com.au/services/specialist-support-coordination' />

        <meta name='twitter:title' content='Specialist Support Coordination | More About the Journey' />
        <meta name='twitter:description' content='Our Specialist Support Coordinators work within an NDIS specialist framework, in order to provide quality support for participants in high risk situations.' />
      </Helmet>

      <Header />
      <PageHeading title='Specialist Support Coordination' />
      <Flex
        direction='column'
        justify='left'
        w={ isMaxWidth ? 800 : '90vw' }
        mx='auto'
        mt='xl'
        mb={80}
      >
        <Text fz={ isMobile ? 'sm' : 'md' }>
          The team is made up of professionals who have years of experience delivering Complex Case Management and Specialist Support Coordination to people with a range of primary diagnoses, including but not limited to ASD, ABI, Psychosocial Disability, and dual disabilities (mental health and another disability diagnosis).
        </Text>
        <Space h={20} />
        <Text fz={ isMobile ? 'sm' : 'md' }>
          Our Specialist Support Coordinators work within an NDIS specialist framework, necessitated by high-level risks present in the participant's current situation. The support is time-limited and focuses on addressing barriers and reducing complexity in the support environment, while assisting the participant to connect with services and build capacity and resilience. It may also involve the development of an intervention plan which will be put into place and supported by Disability or Mental Health support workers.
        </Text>
        <SupportForm />
      </Flex>
      <Footer />
    </>
  )
}

export default SpecialistSupportCoordination