import { Helmet  } from 'react-helmet'
import { Flex, Text, Space, Title, Anchor } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

import Header from '../components/Header'
import Footer from '../components/Footer'
import PageHeading from '../components/PageHeading'

const ServiceAgreement = () => {
  const isMaxWidth = useMediaQuery('(min-width: 800px)')
  const isMobile = useMediaQuery('(max-width: 800px)')

  return (
    <>
      <Helmet>
        <title>Service Agreement | More About the Journey</title>
        <meta name='description' content='This agreement is between you and More About the Journey, and is for the purpose of providing you Support Coordination as allocated in your NDIS Plan.' />

        <meta property='og:title' content='Service Agreement' />
        <meta property='og:description' content='This agreement is between you and More About the Journey, and is for the purpose of providing you Support Coordination as allocated in your NDIS Plan.' />
        <meta property='og:url' content='https://moreaboutthejourney.com.au/service-agreement' />

        <meta name='twitter:title' content='Service Agreement | More About the Journey' />
        <meta name='twitter:description' content='This agreement is between you and More About the Journey, and is for the purpose of providing you Support Coordination as allocated in your NDIS Plan.' />
      </Helmet>

      <Header />
      <PageHeading title='Service Agreement' />
      <Flex
        direction='column'
        justify='left'
        w={ isMaxWidth ? 800 : '90vw' }
        mx='auto'
        mt='xl'
        mb={80}
      >
        <Text fz={ isMobile ? 'sm' : 'md' }>
          This agreement is between you and More About the Journey (MAJ), and is for the purpose of providing you Support Coordination as allocated in your NDIS Plan.
          </Text>
        <Space h={20} />
        <Text fz={ isMobile ? 'sm' : 'md' }>
          This <strong>Service Agreement</strong> will continue for your next <strong>Plan</strong> in accordance with the <strong>Current Price Guide</strong> for that year.
        </Text>
        <Space h={20} />
        <Text fz={ isMobile ? 'sm' : 'md' }>
          We will continue to provide you with Support Coordination as your new NDIS plans are approved. Please advise us of any new plan and send a copy to <i>admin@moreaboutthejourney.com.au</i>.
          </Text>
        <Space h={20} />
        <Text fz={ isMobile ? 'sm' : 'md' }>
          If you do not wish to continue with our Support Coordination services when you receive your new Plan, just email or call us and let us know.
        </Text>

        <Title size='28px' fw={500} mt={50} mb={20} color='brand-blue'>Responsibilities of More About the Journey (MAJ)</Title>
        <Text fz={ isMobile ? 'sm' : 'md' }>MAJ agrees to:</Text>
        <Text fz={ isMobile ? 'sm' : 'md' }>
          <ul>
            <li>Communicate openly and honestly in a timely manner</li>
            <li>Treat you with courtesy and respect</li>
            <li>Respond quickly and act if you make a complaint</li>
            <li>Give you notice if we have to end the Service Agreement</li>
            <li>Protect your privacy and confidentiality</li>
            <li>Abide by the law when providing supports and keep up to date notes of the services we have provided you</li>
            <li>Charge within NDIA regulation price guidelines</li>
          </ul>
        </Text>

        <Title size='28px' fw={500} mt={30} mb={20} color='brand-blue'>Responsibilities of the participant</Title>
        <Text fz={ isMobile ? 'sm' : 'md' }>You agree to:</Text>
        <Text fz={ isMobile ? 'sm' : 'md' }>
          <ul>
            <li>Inform us about how you wish the supports be delivered to best meet your needs</li>
            <li>Treat us with courtesy and respect</li>
            <li>Talk to us if you have any concerns about the supports being provided</li>
            <li>Talk to us if you need to end this Service Agreement</li>
            <li>Inform us if your NDIS plan is suspended or changed</li>
            <li>Inform us when your plan is going to be reviewed by the NDIA</li>
          </ul>
        </Text>

        <Title size='28px' fw={500} mt={30} mb={20} color='brand-blue'>Service Bookings</Title>
        <Text fz={ isMobile ? 'sm' : 'md' }>
          MAJ will create Service Bookings on your behalf using the NDIS portal, and we will provide a copy of this service agreement to your Plan Manager to have them create a service booking for payment of your invoices. Self-managed participants' invoices will be emailed to the email address provided for payment.
        </Text>

        <Title size='28px' fw={500} mt={50} mb={20} color='brand-blue'>Termination Period</Title>
        <Text fz={ isMobile ? 'sm' : 'md' }>
          Please let us know if you wish to stop this agreement. We ask that you provide us with two weeks' notice. We will also give you two weeks' notice if for any reason we need to terminate this Service Agreement. Don't worry, we will talk to you first if we need to do this for any reason.
        </Text>

        <Title size='28px' fw={500} mt={50} mb={20} color='brand-blue'>Price Increases</Title>
        <Text fz={ isMobile ? 'sm' : 'md' }>MAJ reserves the right to increase prices in accordance with the NDIA Price Guide.</Text>

        <Title size='28px' fw={500} mt={50} mb={20} color='brand-blue'>Confidentiality & Privacy</Title>
        <Text fz={ isMobile ? 'sm' : 'md' }>
          MAJ will keep your personal and sensitive information safe and secure. This will be done in accordance with Privacy Laws and the Australian Privacy Principles.
        </Text>
        <Space h={20} />
        <Text fz={ isMobile ? 'sm' : 'md' }>
          MAJ, with your permission, may share your personal information with people who are involved with your support. This information will be used to help you achieve your goals and make sure we can connect you to the best supports available.
        </Text>
        <Space h={20} />
        <Text fz={ isMobile ? 'sm' : 'md' }>
          You can decide to not share your personal information, however this will make it difficult for us to help you achieve your goals and link you to supports.
        </Text>
        <Space h={20} />
        <Text fz={ isMobile ? 'sm' : 'md' }>
          If you would like to see your personal information, you can request this from us.
        </Text>
        <Space h={20} />
        <Text fz={ isMobile ? 'sm' : 'md' }>
          There may be certain situations where we are required to release your personal information.
        </Text>
        <Space h={20} />
        <Text fz={ isMobile ? 'sm' : 'md' }>These situations would be:</Text>
        <Text fz={ isMobile ? 'sm' : 'md' }>
          <ul>
            <li>If this is required or authorised by law</li>
            <li>We believe not doing so may place you at risk of harm</li>
          </ul>
        </Text>

        <Title size='28px' fw={500} mt={30} mb={20} color='brand-blue'>Changes to this Service Agreement</Title>
        <Text fz={ isMobile ? 'sm' : 'md' }>
          If changes to the supports or their delivery are required, we can both discuss and review this Service Agreement. We will then make changes and provide you with an updated Service Agreement.
        </Text>
        <Space h={20} />
        <Text fz={ isMobile ? 'sm' : 'md' }>
          To talk with us about your Service Agreement, call us on <strong>03 7042 1059</strong>.
        </Text>

        <Title size='28px' fw={500} mt={50} mb={20} color='brand-blue'>Complaints & Feedback</Title>
        <Text fz={ isMobile ? 'sm' : 'md' }>
          If you are not happy or satisfied with the services we have provided, or the way we have managed your personal information, you can:
        </Text>
        <Text fz={ isMobile ? 'sm' : 'md' }>
          <ol>
            <li>Speak with your Support Coordinator</li>
            <li>Email the complaint to <i>admin@moreaboutthejourney.com.au</i></li>
            <li>Write to or call us on <strong>03 7042 1059</strong> or <strong>3/98 Nixon Street, Shepparton Vic 3630</strong>.</li>
          </ol>
        </Text>
        <Text fz={ isMobile ? 'sm' : 'md' }>If you do not want to speak with us at MAJ, or you are still unhappy, you can contact:</Text>
        <Space h={10} />
        <Text fz={ isMobile ? 'sm' : 'md' }>
          <strong>The National Disability Insurance Agency</strong><br />
          Call 1800 800 110;<br />
          Visit one of their offices in person; or<br />
          Visiting <Anchor href='https://ndis.gov.au/contact/feedback-and-complaints' target='_blank'>Feedback and complaints</Anchor>
        </Text>
        <Space h={10} />
        <Text fz={ isMobile ? 'sm' : 'md' }>
          <strong>Quality and Safeguards Commission</strong><br />
          Call 1800 035 544; or<br />
          Visiting <Anchor href='https://ndiscommission.gov.au/about/making-complaint' target='_blank'>Making a complaint</Anchor>
        </Text>

        <Title size='28px' fw={500} mt={50} mb={20} color='brand-blue'>Client Consent</Title>
        <Text fz={ isMobile ? 'sm' : 'md' }>
          This section of the Service Agreement explains Consent and how we will collect your information and communicate with providers.
        </Text>
        <Text fz={ isMobile ? 'sm' : 'md' }>By agreeing to this Service Agreement, you agree for MAJ to:</Text>
        <Text fz={ isMobile ? 'sm' : 'md' }>
          <ul>
            <li>Collect personal information</li>
            <li>Keep personal information</li>
            <li>Share personal information</li>
          </ul>
        </Text>

        <Title size='20px' fw={500} my={10} color='brand-purple'>Consent to contact People and Providers regarding your NDIS Plan.</Title>
        <Text fz={ isMobile ? 'sm' : 'md' }>
          To help us support you, at times it is beneficial to speak with other people and providers about you. This means we can assist you to manage your NDIS funds and supports.
        </Text>

        <Title size='20px' fw={500} mt={20} mb={10} color='brand-purple'>You can change who you consent us to speak with at any time.</Title>
        <Text fz={ isMobile ? 'sm' : 'md' }>
          If you would like to do this, please let your Support Coordinator know, and they will make a note of this on your file, and not share information with that person or provider again.
        </Text>
        <Space h={20} />
        <Text fz={ isMobile ? 'sm' : 'md' }>
          We will add specific providers to your Consent list as they are engaged, but to assist us initially implement your plan, we will contact:
        </Text>
        <Text fz={ isMobile ? 'sm' : 'md' }>
          <ul>
            <li>Your NDIS Nominee or nominated family member</li>
            <li>The NDIA or your LAC</li>
            <li>Your Allied Health Supports (Occupational Therapist, Speech Pathologist, Physiotherapist etc)</li>
            <li>Your Support Providers (Support Worker Agency, Cleaner, Gardener etc)</li>
            <li>Your NDIS Plan Manager (if applicable)</li>
          </ul>
        </Text>
        <Text fz={ isMobile ? 'sm' : 'md' }>If there are people or providers on this list that you do not wish for us to contact, please let us know and we will remove them from this list.</Text>

        <Title size='28px' fw={500} mt={50} mb={20} color='brand-blue'>Audit Process</Title>
        <Text fz={ isMobile ? 'sm' : 'md' }>
          As part of our commitment to quality service, we undertake an external Quality and Safeguards Commission audit every two years. This audit may require that your NDIS files are reviewed, and you may be called and requested to speak to the nominated Auditors about how MAJ provides services to you. All information will be kept private. We will contact you when there is going to be an audit to provide you with further details. You do not have to participate in this audit if you do not wish to.
        </Text>
      </Flex>
      <Footer />
    </>
  )
}

export default ServiceAgreement