import { Title, Button } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { HeroImage } from '../theme'

const Hero = () => {
  const isMobile = useMediaQuery('(max-width: 525px)')

  return (
    <HeroImage>
      <Title
        color='white'
        mb={30}
        fw={600}
        size={isMobile ? '36px' : '56px'}
      >
        Supporting Your Journey
      </Title>
      <Button
        component='a'
        href='/services'
        color='brand-purple'
        size={ isMobile ? 'xs' : 'md'}
      >
        How We Help
      </Button>
    </HeroImage>
  )
}

export default Hero