import { Flex, Title, Text, Anchor, Divider } from '@mantine/core'

const Tasmania = () => {
  return (
    <>
      <Flex
        direction='column'
        gap={25}
        px={8}
      >
        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Companion Card</Title>
          <Text fz='sm'>
            <p>The Tasmanian Government Companion Card entitles eligible people with lifelong disability to a free ticket for their companion and/or carer at participating venues and events.</p>
            <strong>Phone:</strong> <Anchor href='tel:1800009501'>1800 009 501</Anchor><br />
            <strong>Email:</strong> <Anchor href='mailto:companion.card@dpac.tas.gov.au'>companion.card@dpac.tas.gov.au</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.companioncard.tas.gov.au/' target='_blank'>Tasmanian Government Companion Card</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Disability Parking Permits</Title>
          <Text fz='sm'>
            <p>Disability Parking Permits allow people with mobility issues to access parks in designated zones.</p>
            <strong>Phone:</strong> <Anchor href='tel:1300135513'>1300 135 513</Anchor><br />
            <strong>Email:</strong> <Anchor href='mailto:passenger.transport@stategrowth.tas.gov.au'>passenger.transport@stategrowth.tas.gov.au</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.transport.tas.gov.au/public_transport/concessions/disability_parking_permits' target='_blank'>Disability Parking Permits</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Parent Line</Title>
          <Text fz='sm'>
            <p>The Parent Line offers 24-hour information and support for parents throughout Tasmania.</p>
            <strong>Phone:</strong> <Anchor href='tel:1300808178'>1300 808 178</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.health.tas.gov.au/health-topics/child-and-youth-health/child-health-and-parenting-service-chaps/parent-line' target='_blank'>Parent Line</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Taxi Subsidy Program</Title>
          <Text fz='sm'>
            <p>Taxi Subsidy Program members qualify for subsidised taxi travel through the use of a Taxi Smartcard.</p>
            <strong>Website:</strong> <Anchor href='https://www.transport.tas.gov.au/public_transport/concessions/taxi_subsidy_program' target='_blank'>Taxi Subsidy Program</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Vision Impaired Travel Pass</Title>
          <Text fz='sm'>
            <p>Free travel on public transport is available to holders of a Vision Impaired Travel Pass, guide dog and attendant.</p>
            <strong>Website:</strong> <Anchor href='https://www.transport.tas.gov.au/public_transport/concessions/vision_impaired_travel_pass' target='_blank'>Vision Impaired Travel Pass</Anchor>
          </Text>
        </Flex>
      </Flex>
    </>
  )
}

export default Tasmania