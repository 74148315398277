import { Flex, Title, SimpleGrid, Text, Button, Box, BackgroundImage, Space } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { ArrowCircleRight } from '@phosphor-icons/react'

import HoldingHands from '../assets/images/holding-hands.jpg'

const AboutUs = () => {
  const isMaxWidth = useMediaQuery('(min-width: 1000px)')
  const isMobile = useMediaQuery('(max-width: 550px)')
  const isTablet = useMediaQuery('(max-width: 660px)')

  return (
    <Flex
      justify='center'
      w={ isMaxWidth ? 1000 : '95vw' }
      mx='auto'
    >
      <SimpleGrid cols={ isTablet ? 1 : 2 } spacing={50}>
        <Box>
          <BackgroundImage
            src={HoldingHands}
            radius={10}
          >
            <Space w={ isTablet ? '100vw' : 500 } h={ isMobile ? 200 : 300 } />
          </BackgroundImage>
        </Box>
        <Flex
          direction='column'
          justify='center'
          align='center'
        >
          <Title size={ isMobile ? '36px' : '44px' } fw={600} mb={30} ta='center' color='brand-teal'>About Us</Title>
          <Text fz={ isMobile ? 'sm' : 'md' } sx={{ lineHeight: '170%' }}>
            We are a team of Support Coordinators with strong experience in the disability sector, disability services, and an in-depth understanding of the NDIS.
          </Text>
          <Button
            component='a'
            href='/about'
            variant='subtle'
            color='brand-purple'
            rightIcon={<ArrowCircleRight size={20} weight='duotone' />}
            mt='lg'
          >
            Learn More
          </Button>
        </Flex>
      </SimpleGrid>
    </Flex>
  )
}

export default AboutUs