import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { MantineProvider } from '@mantine/core'
import GlobalFonts from './assets/fonts/fonts'

// Pages
import Home from './pages/Home'
import About from './pages/About'
import Services from './pages/Services'
import AccessApplications from './pages/AccessApplications'
import SupportCoordination from './pages/SupportCoordination'
import SpecialistSupportCoordination from './pages/SpecialistSupportCoordination'
import PsychosocialRecoveryCoaching from './pages/PsychosocialRecoveryCoaching'
import ServiceAgreement from './pages/ServiceAgreement'
import Resources from './pages/Resources'
import Contact from './pages/Contact'
import Feedback from './pages/Feedback'
import Error from './pages/Error'

const router = createBrowserRouter([
  { path: '/', element: <Home />, errorElement: <Error /> },
  { path: '/about', element: <About /> },
  { path: '/services', element: <Services /> },
  { path: '/services/access-applications', element: <AccessApplications /> },
  { path: '/services/support-coordination', element: <SupportCoordination /> },
  { path: '/services/specialist-support-coordination', element: <SpecialistSupportCoordination /> },
  { path: '/services/psychosocial-recovery-coaching', element: <PsychosocialRecoveryCoaching /> },
  { path: '/service-agreement', element: <ServiceAgreement /> },
  { path: '/resources', element: <Resources /> },
  { path: '/contact', element: <Contact /> },
  { path: '/feedback', element: <Feedback /> }
])

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <MantineProvider withNormalizeCSS
    theme={{
      headings: {
        fontFamily: 'Montserrat',
        sizes: {
          h2: { fontSize: '48px', fontWeight: '600' },
          h3: { fontSize: '20px', fontWeight: '600', lineHeight: '26px' }
        },
      },
      primaryShade: 4,
      colors: {
        'brand-teal': ['#DDFBFE', '#BCECEF', '#99DEE2', '#73CFD7', '#59C5CD', '#35A8B0', '#248389', '#155E63', '#02393D', '#001618'],
        'brand-lightblue': ['#B5EAF9', '#B5EAF9', '#75D5F0', '#4EC6E8', '#3ABADF', '#23A4CA', '#15809E', '#075B72', '#003846', '#00141C'],
        'brand-blue': ['#DDF7FF', '#B7E2F8', '#8FCEED', '#65BAE4', '#299DD8', '#238DC2', '#166D98', '#094E6E', '#003045', '#00121D'],
        'brand-darkblue': ['#C4CFF0', '#9FB0E1', '#7990D2', '#5470C4', '#3853A3', '#2D4486', '#1F3061', '#111D3D', '#030A1B', '#02050D'],
        'brand-purple': ['#D9CBE9', '#BFAAD6', '#A488C4', '#8A66B2', '#75509F', '#573C78', '3E2A57', '#261936', '#0E0718', '#07040B']
      },
    }}
    >
    <React.StrictMode>
      <GlobalFonts />
      <RouterProvider router={router} />
    </React.StrictMode>
  </MantineProvider>
)
