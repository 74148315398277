import { useState } from 'react'
import axios from 'axios'
import { Radio, Group, SimpleGrid, TextInput, Space, Divider, Textarea, Button, Flex, Modal, Text } from '@mantine/core'
import { useMediaQuery, useDisclosure } from '@mantine/hooks'
import { useForm, hasLength, isEmail, isNotEmpty } from '@mantine/form'
import { EnvelopeSimple } from '@phosphor-icons/react'

interface FormValues {
  type: string,
  name: string,
  email: string,
  message: string
}

const FeedbackForm = () => {
  const isTablet = useMediaQuery('(max-width: 800px)')

  const [opened, { open, close }] = useDisclosure(false)
  const [modal, setModal] = useState(close)
  const [sent, setSent] = useState(false)
  const [loading, setLoading] = useState(false)

  const form = useForm({
    initialValues: {
      type: '',
      name: '',
      email: '',
      message: ''
    },
    validate: {
      type: isNotEmpty('Please select an option'),
      name: hasLength({ min: 2 }, 'Name must be at least 2 letters long'),
      email: isEmail('Invalid email address'),
      message: isNotEmpty('Please include a message')
    }
  })

  const handleSubmit = (values: FormValues) => {
    setLoading(true)

    const body = `
      Form submission from More About the Journey's <b>Feedback</b> page:<br /><br />
      
      --<br /><br />

      <b>Type of Feedback:</b><br />${values.type}<br /><br />
      <b>Name:</b><br />${values.name}<br /><br />
      <b>Email:</b><br />${values.email}<br /><br />
      <b>Message:</b><br />
      ${values.message}<br /><br />
    `

    axios.get('https://api.elasticemail.com/v2/email/send', {
      params: {
        apikey: process.env.REACT_APP_EMAIL_API_KEY,
        subject: 'Feedback Form Submission: ' + values.name,
        from: process.env.REACT_APP_EMAIL_USER,
        fromName: 'More About the Journey Website',
        to: process.env.REACT_APP_EMAIL_USER,
        bodyHtml: body,
        replyTo: values.email,
        headers: { 'Access-Control-Allow-Origin': '*' }
      }
    })
    .then((response) => {
      // console.log(response)
      setSent(true)
      setModal(open)
    })
    .catch((error) => {
      console.log(error)
      setSent(false)
      setModal(open)
    })
  }

  const handleClose = () => {
    setLoading(false)
    setModal(close)
  }

  return (
    <>
      <form onSubmit={form.onSubmit((values) => {handleSubmit(values)})}>
        <Radio.Group
          label='Are you offering feedback or issuing a complaint?'
          {...form.getInputProps('type')}
        >
          <Group mt={5}>
            <Radio value='Feedback' label='Feedback' color='brand-teal' />
            <Radio value='Complaint' label='Complaint' color='brand-teal' />
          </Group>
        </Radio.Group>
        <Space h={10} />
        <SimpleGrid cols={isTablet ? 1 : 2}>
          <TextInput
            label='Name'
            placeholder='Your name'
            {...form.getInputProps('name')}
          />
          <TextInput
            label='Email'
            placeholder='Your email address'
            {...form.getInputProps('email')}
          />
        </SimpleGrid>
        <Space h={10} />
        <Textarea
          label="Message"
          placeholder="If you have a complaint, please provide as much information as possible."
          {...form.getInputProps('message')}
        />
        <Space h={10} />
        <Divider mt='sm' mb='lg' color='gray.2' />
        <Flex justify='right'>
          <Button
            type='submit'
            color='brand-teal'
            leftIcon={<EnvelopeSimple size={16} />}
            loading={loading}
          >
            Submit Form
          </Button>
        </Flex>
      </form>
      <Modal
        opened={opened}
        onClose={handleClose}
        title={
          <Text fz='lg' weight='bold' color='brand-blue'>
            { sent ? 'Thank You' : 'Oops, Sorry' }
          </Text>
        }
        centered
        overlayProps={{
          color: '#3853A3',
          opacity: 0.4,
          blur: 3
        }}
      >
        {
          <Text fz='sm'>
            {
              sent
              ? 'Thanks for getting in touch with us. We\'ll do our best to reply to your message as soon as we can.'
              : 'Something seems to have gone wrong submitting your message. Please try again, or email us directly at admin@moreaboutthejourney.com.au'
            }
          </Text>
        }
      </Modal>
    </>
  )
}

export default FeedbackForm