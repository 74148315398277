import { Flex, Title, Text, Anchor, Divider } from '@mantine/core'

const AustralianCapitalTerritory = () => {
  return (
    <>
      <Flex
        direction='column'
        gap={25}
        px={8}
      >
        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Companion Card</Title>
          <Text fz='sm'>
            <p>The Companion Card Program enables people with disability who require life-long attendant care support to attend participating venues or activities.</p>
            <strong>Website:</strong> <Anchor href='https://www.communityservices.act.gov.au/companion_card' target='_blank'>ACT Companion Card Program</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />
        
        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Parentline ACT</Title>
          <Text fz='sm'>
            <p>Free counselling for supporting parents and carers given through telephone and face to face counselling and education.</p>
            <strong>Phone:</strong> <Anchor href='tel:0262873833'>02 6287 3833</Anchor><br />
            <strong>Email:</strong> <Anchor href='mailto:parentline@parentlineact.org.au'>parentline@parentlineact.org.au</Anchor><br />
            <strong>Website:</strong> <Anchor href='hhttp://parentlineact.org.au/' target='_blank'>Parentline</Anchor>
          </Text>
        </Flex>
        
        <Divider color='brand-teal' w='75%' mx='auto' />
        
        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Parking Permits</Title>
          <Text fz='sm'>
            <p>A disability parking permit is for people with certain disabilities. You may be eligible for a disability parking permit if you are an ACT resident and you meet the criteria.</p>
            <strong>Website:</strong> <Anchor href='https://www.accesscanberra.act.gov.au/s/article/parking-permits-tab-australian-disability-parking-permit' target='_blank'>Parking permits</Anchor>
          </Text>
        </Flex>
      </Flex>
    </>
  )
}

export default AustralianCapitalTerritory