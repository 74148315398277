import { Helmet } from 'react-helmet'
import { Flex, Grid, Text, Space } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

import Header from '../components/Header'
import Footer from '../components/Footer'
import PageHeading from '../components/PageHeading'
import FeedbackForm from '../components/FeedbackForm'

const Feedback = () => {
  const isMaxWidth = useMediaQuery('(min-width: 1000px)')
  const isTablet = useMediaQuery('(max-width: 800px)')
  const isMobile = useMediaQuery('(max-width: 550px)')

  return (
    <>
      <Helmet>
        <title>Feedback & Complaints | More About the Journey</title>
        <meta name='description' content='Get in touch with the team at More About the Journey if you have any feedback or complaints you wish to tell us.' />

        <meta property='og:title' content='Feedback & Complaints' />
        <meta property='og:description' content='Get in touch with the team at More About the Journey if you have any feedback or complaints you wish to tell us.' />
        <meta property='og:url' content='https://moreaboutthejourney.com.au/feedback' />

        <meta name='twitter:title' content='Feedback & Complaints | More About the Journey' />
        <meta name='twitter:description' content='Get in touch with the team at More About the Journey if you have any feedback or complaints you wish to tell us.' />
      </Helmet>

      <Header />
      <PageHeading title='Feedback & Complaints' />
      <Flex
        direction='column'
        justify='left'
        w={ isMaxWidth ? 1000 : '90vw' }
        mx='auto'
        mt='xl'
        mb={80}
      >
        <Grid gutter='xl'>
          <Grid.Col span={isTablet ? (isMobile ? 12 : 6) : 6}>
            <Flex direction='column' >
              <Text fz={ isMobile ? 'sm' : 'md' }>
                If you have any <strong>complaints about our services</strong>, or services provided to you by others, please feel free to contact us using the form on this page.
                </Text>
              <Space h={20} />
              <Text fz={ isMobile ? 'sm' : 'md' }>
                Please know that <strong>we take all complaints seriously</strong>, and we will work together with you to resolve any concerns.
                </Text>
              <Space h={20} />
              <Text fz={ isMobile ? 'sm' : 'md' }>
                Similarly, <strong>if you have any feedback</strong>, good or bad, feel free to let us know. Our staff love hearing your success stories, and how you're travelling on your own journey.
                </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={isTablet ? (isMobile ? 12 : 6) : 6}>
            <FeedbackForm />
          </Grid.Col>
        </Grid>
      </Flex>
      <Footer />
    </>
  )
}

export default Feedback