import { useState } from 'react'
import axios from 'axios'
import { Flex, Title, SimpleGrid, NativeSelect, TextInput, Space, Divider, Radio, Group, Anchor, Button, Modal, Text } from '@mantine/core'
import { useMediaQuery, useDisclosure } from '@mantine/hooks'
import { useForm, hasLength, isEmail, isNotEmpty, matches } from '@mantine/form'
import { EnvelopeSimple } from '@phosphor-icons/react'

interface FormValues {
  name: {
    title: string,
    given: string,
    preferred: string,
    surname: string,
    other_radio: string,
    other: string,
    type: string
  },
  gender: {
    preferred_radio: string,
    preferred: string,
    pronouns: string
  },
  dob: string,
  birth_country: string,
  aboriginal_tsi: string,
  permanent_resident: string,
  aus_citizen: string,
  visa_type: string,
  resident_visa_type: string,
  nationality: string,
  crn: string,
  home: {
    address: string,
    suburb: string,
    state: string,
    postcode: string
  },
  postal_radio: string,
  postal: {
    address: string,
    suburb: string,
    state: string,
    postcode: string
  },
  ndis_family: string,
  family: {
    1: {
      name: string,
      ndis_no: string,
      relationship: string
    },
    2: {
      name: string,
      ndis_no: string,
      relationship: string
    },
    3: {
      name: string,
      ndis_no: string,
      relationship: string
    },
    4: {
      name: string,
      ndis_no: string,
      relationship: string
    }
  },
  privacy_consent: string,
  correspondence: string,
  contact: {
    phone: string,
    email: string,
    sms: string
  },
  language: string,
  interpreter: string,
  int_language: string,
  rep: {
    name: {
      given: string,
      surname: string
    },
    relationship: string,
    authority: string,
    dob: string,
    phone: string,
    email: string,
    postal: {
      address: string,
      suburb: string,
      state: string,
      postcode: string
    },
    sms: string,
    interpreter: string,
    int_language: string
  },
  disability: {
    primary: string,
    other: string,
    cause: string,
    date: string,
    compensation: {
      finalised: string,
      date: string,
      payment: string,
      legal: string
    }
  },
  gp: {
    name: string,
    phone: string,
    postal: {
      address: string,
      suburb: string,
      state: string,
      postcode: string
    },
    patient_time: string
  }
}

const RegisterForm = () => {
  const isTablet = useMediaQuery('(max-width: 450px)')
  
  const [opened, { open, close }] = useDisclosure(false)
  const [modal, setModal] = useState(close)
  const [sent, setSent] = useState(false)
  const [loading, setLoading] = useState(false)

  const form = useForm({
    initialValues: {
      name: {
        title: '',
        given: '',
        preferred: '',
        surname: '',
        other_radio: '',
        other: '',
        type: '',
      },
      gender: {
        preferred_radio: '',
        preferred: '',
        pronouns: '',
      },
      dob: '',
      birth_country: '',
      aboriginal_tsi: '',
      permanent_resident: '',
      aus_citizen: '',
      visa_type: '',
      resident_visa_type: '',
      nationality: '',
      crn: '',
      home: {
        address: '',
        suburb: '',
        state: '',
        postcode: ''
      },
      postal_radio: '',
      postal: {
        address: '',
        suburb: '',
        state: '',
        postcode: ''
      },
      ndis_family: '',
      family: {
        1: {
          name: '',
          ndis_no: '',
          relationship: ''
        },
        2: {
          name: '',
          ndis_no: '',
          relationship: ''
        },
        3: {
          name: '',
          ndis_no: '',
          relationship: ''
        },
        4: {
          name: '',
          ndis_no: '',
          relationship: ''
        }
      },
      privacy_consent: '',
      correspondence: '',
      contact: {
        phone: '',
        email: '',
        sms: ''
      },
      language: '',
      interpreter: '',
      int_language: '',
      rep: {
        name: {
          given: '',
          surname: ''
        },
        relationship: '',
        authority: '',
        dob: '',
        phone: '',
        email: '',
        postal: {
          address: '',
          suburb: '',
          state: '',
          postcode: ''
        },
        sms: '',
        interpreter: '',
        int_language: ''
      },
      disability: {
        primary: '',
        other: '',
        cause: '',
        date: '',
        compensation: {
          finalised: '',
          date: '',
          payment: '',
          legal: ''
        }
      },
      gp: {
        name: '',
        phone: '',
        postal: {
          address: '',
          suburb: '',
          state: '',
          postcode: ''
        },
        patient_time: ''
      }
    },
    validate: {
      name: {
        title: matches(/M.+/, 'Please select a title'),
        given: hasLength({ min: 2 }, 'Name must contain at least 2 characters'),
        surname: hasLength({ min: 2 }, 'Surname must contain at least 2 characters'),
        other_radio: isNotEmpty('Please select an option')
      },
      gender: {
        preferred_radio: isNotEmpty('Please select an option')
      },
      dob: matches(/\d{1,2}\/\d{1,2}\/(\d{4}|\d{2})/, 'Please use the format DD/MM/YYYY'),
      birth_country: hasLength({ min: 2 }, 'Country must contain at least 2 characters'),
      aboriginal_tsi: isNotEmpty('Please select an option'),
      permanent_resident: isNotEmpty('Please select an option'),
      aus_citizen: isNotEmpty('Please select an option'),
      crn: matches(/\d{9}([A-Z|a-z]{1})/, 'CRN should be formatted as 9 digits and a letter'),
      home: {
        address: isNotEmpty('Please include your home address'),
        suburb: isNotEmpty('Please include your suburb'),
        state: matches(/[A-Z,a-z]{1,}/, 'Please select a state'),
        postcode: matches(/\d{4}/, 'Please use a valid postcode')
      },
      postal_radio: isNotEmpty('Please select an option'),
      ndis_family: isNotEmpty('Please select an option'),
      privacy_consent: isNotEmpty('Please read and agree to the privacy policy'),
      correspondence: isNotEmpty('Please select an option'),
      contact: {
        phone: matches(/\d{10}|\d{8}/, 'Phone number must be either 8 or 10 digits long'),
        sms: isNotEmpty('Please select an option')
      },
      language: isNotEmpty('Please include a language'),
      interpreter: isNotEmpty('Please select an option'),
      disability: {
        primary: isNotEmpty('Please include a primary disability'),
        cause: isNotEmpty('Please select an option')
      },
      gp: {
        name: hasLength({ min: 2 }, 'Name must contain at least 2 characters'),
        phone: matches(/\d{10}|\d{8}/, 'Phone number must be either 8 or 10 digits long'),
        postal: {
          address: isNotEmpty('Please include your home address'),
          suburb: isNotEmpty('Please include your suburb'),
          state: matches(/[A-Z,a-z]{1,}/, 'Please select a state'),
          postcode: matches(/\d{4}/, 'Please use a valid postcode')
        },
        patient_time: isNotEmpty('Please select an option')
      }
    }
  })

  const handleSubmit = (values: FormValues) => {
    setLoading(true)

    const body = `
      Form submission from More About the Journey's <b>Access Application</b> page:<br /><br />

      <span style="font-weight:600;font-size:150%">${values.name.given} ${values.name.surname}</span><br /><br />
      
      --<br /><br />

      <b><u>Applicant's Information</u></b><br /><br />
      <b>Title:</b><br />${values.name.title}<br /><br />
      <b>Given Name/s:</b><br />${values.name.given}<br /><br />
      <b>Surname:</b><br />${values.name.surname}<br /><br />
      <b>Preferred Name:</b><br />${values.name.preferred}<br /><br />
      <b>Have you ever been known by another name?:</b><br />${values.name.other_radio}<br /><br />
      <b>If yes, other name:</b><br />${values.name.other}<br /><br />
      <b>Type of name:</b><br />${values.name.type}<br /><br />
      <b>Preferred gender/pronoun:</b><br />${values.gender.preferred_radio}<br /><br />
      <b>If other, gender:</b><br />${values.gender.preferred}<br /><br />
      <b>If other, preferred pronouns:</b><br />${values.gender.pronouns}<br /><br />
      <b>Date of Birth:</b><br />${values.dob}<br /><br />
      <b>Country of birth:</b><br />${values.birth_country}<br /><br />
      <b>Are you of Aboriginal or Torress Strait Islander origin?:</b><br />${values.aboriginal_tsi}<br /><br />
      <b>Are you living in Australia permanently?:</b><br />${values.permanent_resident}<br /><br />
      <b>Are you an Australian citizen?:</b><br />${values.aus_citizen}<br /><br />
      <b>What type of Visa do you have (if any)?:</b><br />${values.visa_type}<br /><br />
      <b>If providing details, visa type:</b><br />${values.resident_visa_type}<br /><br />
      <b>Nationality:</b><br />${values.nationality}<br /><br />
      <b>Centrelink customer reference number:</b><br />${values.crn}<br /><br />
      <b>Home address:</b><br />${values.home.address}, ${values.home.suburb} ${values.home.state} ${values.home.postcode}<br /><br />
      <b>Is your postal address the same as your home address?:</b><br />${values.postal_radio}<br /><br />
      <b>If no, postal address:</b><br />${values.postal.address}, ${values.postal.suburb} ${values.postal.state} ${values.postal.postcode}<br /><br />
      <b>Do you have any immediate family who is applying for the NDIS or is already a participant?:</b><br />${values.ndis_family}<br /><br />
      <b>If yes, family member 1:</b><br />${values.family[1].name}${', NDIS #' + values.family[1].ndis_no}${', ' + values.family[1].relationship}<br /><br />
      <b>If yes, family member 2:</b><br />${values.family[2].name}${', NDIS #' + values.family[2].ndis_no}${', ' + values.family[2].relationship}<br /><br />
      <b>If yes, family member 3:</b><br />${values.family[3].name}${', NDIS #' + values.family[3].ndis_no}${', ' + values.family[3].relationship}<br /><br />
      <b>If yes, family member 4:</b><br />${values.family[4].name}${', NDIS #' + values.family[4].ndis_no}${', ' + values.family[4].relationship}<br /><br /><br />

      <b><u>Privacy & Consent Declaration</u></b><br /><br />
      <b>Do you consent to us collecting and using your data?:</b><br />${values.privacy_consent}<br /><br /><br />

      <b><u>Contact Methods</u></b><br /><br />
      <b>Would you prefer us to send you letters or email?:</b><br />${values.correspondence}<br /><br />
      <b>Phone number:</b><br />${values.contact.phone}<br /><br />
      <b>Email:</b><br />${values.contact.email}<br /><br />
      <b>Can we use SMS to contact you?:</b><br />${values.contact.sms}<br /><br />
      <b>Main language spoken at home:</b><br />${values.language}<br /><br />
      <b>Do you need an interpreter?:</b><br />${values.interpreter}<br /><br />
      <b>If yes, language:</b><br />${values.int_language}<br /><br />

      <b><u>Parent, Legal Guardian or Representative Details</u></b><br /><br />
      <b>First name/s:</b><br />${values.rep.name.given}<br /><br />
      <b>Surname:</b><br />${values.rep.name.surname}<br /><br />
      <b>Relationship:</b><br />${values.rep.relationship}<br /><br />
      <b>Authority to act on applicant's behalf:</b><br />${values.rep.authority}<br /><br />
      <b>Date of Birth:</b><br />${values.rep.dob}<br /><br />
      <b>Phone number:</b><br />${values.rep.phone}<br /><br />
      <b>Email address:</b><br />${values.rep.email}<br /><br />
      <b>Postal address:</b><br />${values.rep.postal.address}, ${values.rep.postal.suburb} ${values.rep.postal.state} ${values.rep.postal.postcode}<br /><br />
      <b>Can we SMS you?:</b><br />${values.rep.sms}<br /><br />
      <b>Do you need an interpreter?:</b><br />${values.rep.interpreter}<br /><br />
      <b>If yes, language:</b><br />${values.rep.int_language}<br /><br />

      <b><u>Overview of Disability</u></b><br /><br />
      <b>Primary disability:</b><br />${values.disability.primary}<br /><br />
      <b>Other disabilities, if any:</b><br />${values.disability.other}<br /><br />
      <b>Was the disability caused by an accident?:</b><br />${values.disability.cause}<br /><br />
      <b>If yes, date of accident:</b><br />${values.disability.date}<br /><br />
      <b>Has the compensation claim been finalised?:</b><br />${values.disability.compensation.finalised}<br /><br />
      <b>Date claim was finalised:</b><br />${values.disability.compensation.date}<br /><br />
      <b>Compensation payment amount:</b><br />${values.disability.compensation.payment}<br /><br />
      <b>Have you received legal advice about your compensation claim?:</b><br />${values.disability.compensation.legal}<br /><br />

      <b><u>General Practitioner's Information</u></b><br /><br />
      <b>Doctor's Name:</b><br />${values.gp.name}<br /><br />
      <b>Phone number:</b><br />${values.gp.phone}<br /><br />
      <b>Address:</b><br />${values.gp.postal.address}, ${values.gp.postal.suburb} ${values.gp.postal.state} ${values.gp.postal.postcode}<br /><br />
      <b>Have you been a patient of your GP for over 6 months?:</b><br />${values.gp.patient_time}<br /><br />
    `

    axios.get('https://api.elasticemail.com/v2/email/send', {
      params: {
        apikey: process.env.REACT_APP_EMAIL_API_KEY,
        subject: 'Access Applications Registration: ' + values.name.given + ' ' + values.name.surname,
        from: process.env.REACT_APP_EMAIL_USER,
        fromName: 'More About the Journey Website',
        to: process.env.REACT_APP_EMAIL_USER,
        bodyHtml: body,
        replyTo: values.contact.email,
        headers: { 'Access-Control-Allow-Origin': '*' }
      }
    })
    .then((response) => {
      // console.log(response)
      setSent(true)
      setModal(open)
    })
    .catch((error) => {
      console.log(error)
      setSent(false)
      setModal(open)
    })
  }

  const handleClose = () => {
    setLoading(false)
    setModal(close)
  }

  return (
    <Flex direction='column' w={isTablet ? '90vw' : 600} mx='auto'>
      <Title size='36px' fw={500} mt={50} mb={20} ta='center' color='brand-blue'>Registration Form</Title>
      <form onSubmit={form.onSubmit((values) => {handleSubmit(values)})}>
        <Title size='24px' fw={500} mb={10} color='brand-teal'>Applicant's Information</Title>
        <SimpleGrid cols={isTablet ? 1 : 2}>
          <NativeSelect
            label='Title'
            data={['Please select one', 'Mr', 'Mrs', 'Ms', 'Miss', 'Mx']}
            withAsterisk
          {...form.getInputProps('name.title')}
          />
          <TextInput
            label='Given name/s'
            placeholder='Given name/s'
            withAsterisk
            {...form.getInputProps('name.given')}
          />
        </SimpleGrid>
        <Space h={10} />
        <SimpleGrid cols={isTablet ? 1 : 2}>
          <TextInput
            label='Preferred name'
            placeholder='Preferred name'
            {...form.getInputProps('name.preferred')}
          />
          <TextInput
            label='Surname'
            placeholder='Surname'
            withAsterisk
            {...form.getInputProps('name.surname')}
          />
        </SimpleGrid>
        <Space h={10} />
        <SimpleGrid>
          <Radio.Group
            label='Have you ever used or been known by another name?'
            withAsterisk
            {...form.getInputProps('name.other_radio')}
          >
            <Group mt={5}>
              <Radio value='Yes' label='Yes' color='brand-teal' />
              <Radio value='No' label='No' color='brand-teal' />
            </Group>
          </Radio.Group>
        </SimpleGrid>
        <Space h={10} />
        <SimpleGrid cols={isTablet ? 1 : 2}>
          <TextInput
            label='If yes, other name'
            placeholder='Other name'
            {...form.getInputProps('name.other')}
          />
          <TextInput
            label='Type of name'
            placeholder='E.g. legal name, name before marriage'
            {...form.getInputProps('name.type')}
          />
        </SimpleGrid>
        <Space h={10} />
        <Divider my="sm" color='gray.2' />
        <Radio.Group
          label='Do you have a preferred gender and/or pronoun? (e.g. female, she/her)'
          withAsterisk
          {...form.getInputProps('gender.preferred_radio')}
        >
          <Group mt={5}>
            <Radio value='Male' label='Male' color='brand-teal' />
            <Radio value='Female' label='Female' color='brand-teal' />
            <Radio value='Not disclosed' label='Do not wish to disclose' color='brand-teal' />
            <Radio value='Other' label='Other (provide details below)' color='brand-teal' />
          </Group>
        </Radio.Group>
        <Space h={10} />
        <SimpleGrid cols={isTablet ? 1 : 2}>
          <TextInput
            label='Preferred gender'
            placeholder='Preferred gender'
            {...form.getInputProps('gender.preferred')}
          />
          <TextInput
            label='Preferred pronouns'
            placeholder='Preferred pronouns'
            {...form.getInputProps('gender.pronouns')}
          />
        </SimpleGrid>
        <Space h={10} />
        <Divider my="sm" color='gray.2' />
        <SimpleGrid cols={isTablet ? 1 : 2}>
          <TextInput
            label='What is your date of birth?'
            placeholder='DD/MM/YYYY'
            withAsterisk
            {...form.getInputProps('dob')}
          />
          <TextInput
            label='What is your country of birth?'
            placeholder='Country of birth'
            withAsterisk
            {...form.getInputProps('birth_country')}
          />
        </SimpleGrid>
        <Space h={20} />
        <Radio.Group
          label='Are you of Aboriginal and/or Torres Strait Islander origin?'
          withAsterisk
          {...form.getInputProps('aboriginal_tsi')}
        >
          <Group mt={5}>
            <Radio value='No' label='No' color='brand-teal' />
            <Radio value='Aboriginal' label='Yes - Aboriginal' color='brand-teal' />
            <Radio value='Torres Strait Islander' label='Yes - Torres Strait Islander' color='brand-teal' />
            <Radio value='Aboriginal and Torres Strait Islander' label='Yes - Aboriginal and Torres Strait Islander' color='brand-teal' />
            <Radio value='Not disclosed' label='Do not wish to disclose' color='brand-teal' />
          </Group>
        </Radio.Group>
        <Space h={20} />
        <SimpleGrid cols={isTablet ? 1 : 2}>
          <Radio.Group
            label='Are you living in Australia permanently?'
            withAsterisk
            {...form.getInputProps('permanent_resident')}
          >
            <Group mt={5}>
              <Radio value='Yes' label='Yes' color='brand-teal' />
              <Radio value='No' label='No' color='brand-teal' />
            </Group>
          </Radio.Group>
          <Radio.Group
            label='Are you an Australian citizen?'
            withAsterisk
            {...form.getInputProps('aus_citizen')}
          >
            <Group mt={5}>
              <Radio value='Yes' label='Yes' color='brand-teal' />
              <Radio value='No' label='No' color='brand-teal' />
            </Group>
          </Radio.Group>
        </SimpleGrid>
        <Space h={20} />
        <SimpleGrid>
          <Radio.Group
            label='What type of Visa do you have? (if applicable)'
            {...form.getInputProps('visa_type')}
          >
            <Group mt={5}>
              <Radio value='Permanent Resident' label='Permanent Residence Visa (provide details below)' color='brand-teal' />
              <Radio value='Protected Special Category' label='Protected Special Category Visa' color='brand-teal' />
              <Radio value='Other' label='Other (provide details below)' color='brand-teal' />
            </Group>
          </Radio.Group>
        </SimpleGrid>
        <Space h={10} />
        <SimpleGrid cols={isTablet ? 1 : 2}>
          <TextInput
            label='Visa type'
            placeholder='Visa type'
            {...form.getInputProps('resident_visa_type')}
          />
          <TextInput
            label='Nationality'
            placeholder='Nationality'
            {...form.getInputProps('nationality')}
          />
        </SimpleGrid>
        <Space h={10} />
        <Divider my="sm" color='gray.2' />
        <SimpleGrid cols={isTablet ? 1 : 2}>
          <TextInput
            label='CRN (Centrelink customer reference number)'
            placeholder='Customer reference number'
            withAsterisk
            {...form.getInputProps('crn')}
          />
        </SimpleGrid>
        <Space h={10} />
        <Divider my="sm" color='gray.2' />
        <SimpleGrid>
          <TextInput
            label='Current home address'
            placeholder='Current home address'
            withAsterisk
            {...form.getInputProps('home.address')}
          />
        </SimpleGrid>
        <Space h={10} />
        <SimpleGrid cols={2}>
          <TextInput
            label='Suburb'
            placeholder='Suburb'
            withAsterisk
            {...form.getInputProps('home.suburb')}
          />
          <Flex direction='row' justify='space-between' gap='md'>
            <NativeSelect
              label='State'
              data={['--', 'ACT', 'NSW', 'NT', 'Qld', 'SA', 'Tas', 'Vic', 'WA']}
              withAsterisk
              {...form.getInputProps('home.state')}
            />
            <TextInput
              label='Postcode'
              placeholder='Postcode'
              withAsterisk
              {...form.getInputProps('home.postcode')}
            />
          </Flex>
        </SimpleGrid>
        <Space h={10} />
        <Divider my="sm" color='gray.2' />
        <Radio.Group
          label='Is your postal address the same as your home address?'
          withAsterisk
          {...form.getInputProps('postal_radio')}
        >
          <Group mt={5}>
            <Radio value='Yes' label='Yes' color='brand-teal' />
            <Radio value='No' label='No - provide details below' color='brand-teal' />
          </Group>
        </Radio.Group>
        <Space h={20} />
        <SimpleGrid>
          <TextInput
            label='Postal address'
            placeholder='Postal address'
            {...form.getInputProps('postal.address')}
          />
        </SimpleGrid>
        <Space h={10} />
        <SimpleGrid cols={2}>
          <TextInput
            label='Suburb'
            placeholder='Suburb'
            {...form.getInputProps('postal.suburb')}
          />
          <Flex direction='row' justify='space-between' gap='md'>
            <NativeSelect
              label='State'
              data={['--', 'ACT', 'NSW', 'NT', 'Qld', 'SA', 'Tas', 'Vic', 'WA']}
              {...form.getInputProps('postal.state')}
            />
            <TextInput
              label='Postcode'
              placeholder='Postcode'
              {...form.getInputProps('postal.postcode')}
            />
          </Flex>
        </SimpleGrid>
        <Space h={10} />
        <Divider my="sm" color='gray.2' />
        <Radio.Group
          label='Do you have an immediate family member who is applying for the NDIS or is already a participant?'
          withAsterisk
          {...form.getInputProps('ndis_family')}
        >
          <Group mt={5}>
            <Radio value='No' label='No' color='brand-teal' />
            <Radio value='Yes' label='Yes - provide their names, NDIS reference number and relationship to you below' color='brand-teal' />
          </Group>
        </Radio.Group>
        <Space h={20} />
        <SimpleGrid cols={3}>
          <TextInput
            label='Family Member 1'
            placeholder='Name'
            {...form.getInputProps('family.1.name')}
          />
          <TextInput
            label='NDIS Number'
            placeholder='NDIS number'
            {...form.getInputProps('family.1.ndis_no')}
          />
          <TextInput
            label='Relationship'
            placeholder='Parent, carer, etc.'
            {...form.getInputProps('family.1.relationship')}
          />
        </SimpleGrid>
        <Space h={10} />
        <SimpleGrid cols={3}>
          <TextInput
            label='Family Member 2'
            placeholder='Name'
            {...form.getInputProps('family.2.name')}
          />
          <TextInput
            label='NDIS Number'
            placeholder='NDIS number'
            {...form.getInputProps('family.2.ndis_no')}
          />
          <TextInput
            label='Relationship'
            placeholder='Parent, carer, etc.'
            {...form.getInputProps('family.2.relationship')}
          />
        </SimpleGrid>
        <Space h={10} />
        <SimpleGrid cols={3}>
          <TextInput
            label='Family Member 3'
            placeholder='Name'
            {...form.getInputProps('family.3.name')}
          />
          <TextInput
            label='NDIS Number'
            placeholder='NDIS number'
            {...form.getInputProps('family.3.ndis_no')}
          />
          <TextInput
            label='Relationship'
            placeholder='Parent, carer, etc.'
            {...form.getInputProps('family.3.relationship')}
          />
        </SimpleGrid>
        <Space h={10} />
        <SimpleGrid cols={3}>
          <TextInput
            label='Family Member 4'
            placeholder='Name'
            {...form.getInputProps('family.4.name')}
          />
          <TextInput
            label='NDIS Number'
            placeholder='NDIS number'
            {...form.getInputProps('family.4.ndis_no')}
          />
          <TextInput
            label='Relationship'
            placeholder='Parent, carer, etc.'
            {...form.getInputProps('family.4.relationship')}
          />
        </SimpleGrid>
        <Title size='24px' fw={500} mt={30} mb={10} color='brand-teal'>Privacy & Consent Declaration</Title>
        <Radio.Group
          label={
            <>
              Do you consent to the NDIA collecting, using and disclosing your personal and sensitive information for the above purposes and in accordance with the NDIA{' '} <Anchor href='https://www.ndis.gov.au/about-us/policies/privacy' target='_blank'>Privacy Policy</Anchor>?
            </>
          }
          withAsterisk
          {...form.getInputProps('privacy_consent')}
        >
          <Group mt={5}>
            <Radio value='Yes' label='Yes' color='brand-teal' />
            <Radio value='No' label='No, I do not consent' color='brand-teal' />
          </Group>
        </Radio.Group>
        <Title size='24px' fw={500} mt={30} mb={10} color='brand-teal'>Contact Methods</Title>
        <Radio.Group
          label='Would you prefer us to send you letters or email?'
          withAsterisk
          {...form.getInputProps('correspondence')}
        >
          <Group mt={5}>
            <Radio value='Letter' label='Letters' color='brand-teal' />
            <Radio value='Email' label='Email' color='brand-teal' />
          </Group>
        </Radio.Group>
        <Space h={20} />
        <SimpleGrid cols={2}>
          <TextInput
            label='Phone number'
            placeholder='Phone number'
            withAsterisk
            {...form.getInputProps('contact.phone')}
          />
          <TextInput
            label='Email address'
            placeholder='Email address'
            {...form.getInputProps('contact.email')}
          />
        </SimpleGrid>
        <Space h={20} />
        <Radio.Group
          label='Can we use SMS to contact you?'
          withAsterisk
          {...form.getInputProps('contact.sms')}
        >
          <Group mt={5}>
            <Radio value='Yes' label='Yes' color='brand-teal' />
            <Radio value='No' label='No' color='brand-teal' />
          </Group>
        </Radio.Group>
        <Space h={10} />
        <Divider my="sm" color='gray.2' />
        <SimpleGrid cols={isTablet ? 1 : 2}>
          <TextInput
            label='What is the main language spoken at your home?'
            placeholder='Language'
            withAsterisk
            {...form.getInputProps('language')}
          />
        </SimpleGrid>
        <Space h={20} />
        <Radio.Group
          label='Do you need an interpreter to help us communicate with you?'
          withAsterisk
          {...form.getInputProps('interpreter')}
        >
          <Group>
            <Radio value='No' label='No' color='brand-teal' />
            <Group>
              <Radio value='Yes' label='Yes - provide language:' color='brand-teal' />
              <TextInput
                placeholder='Language'
                {...form.getInputProps('int_language')}
              />
            </Group>
          </Group>
        </Radio.Group>
        <Title size='24px' fw={500} mt={30} mb={10} color='brand-teal'>Parent, Legal Guardian or Representative Details</Title>
        <SimpleGrid cols={isTablet ? 1 : 2}>
          <TextInput
            label='First name/s'
            placeholder='First name/s'
            {...form.getInputProps('rep.name.given')}
          />
          <TextInput
            label='Surname'
            placeholder='Surname'
            {...form.getInputProps('rep.name.surname')}
          />
        </SimpleGrid>
        <Space h={20} />
        <SimpleGrid cols={isTablet ? 1 : 2}>
          <TextInput
            label='What is your relationship to the applicant?'
            placeholder='E.g. parent, carer'
            {...form.getInputProps('rep.relationship')}
          />
        </SimpleGrid>
        <Space h={20} />
        <Radio.Group
          label='What is your authority to act on behalf of the applicant?'
          {...form.getInputProps('rep.authority')}
        >
          <Group mt={5}>
            <Radio value='Parent' label='Person with parental responsibility' color='brand-teal' />
            <Radio value='Legal representative' label='Legally authorised representative' color='brand-teal' />
            <Radio value='Consent from applicant' label='The applicant has given consent for me to act on their behalf to apply for the NDIS' color='brand-teal' />
          </Group>
        </Radio.Group>
        <Space h={20} />
        <SimpleGrid cols={isTablet ? 1 : 2}>
          <TextInput
            label='Date of birth'
            placeholder='DD/MM/YYYY'
            {...form.getInputProps('rep.dob')}
          />
        </SimpleGrid>
        <Space h={10} />
        <Divider my="sm" color='gray.2' />
        <SimpleGrid cols={isTablet ? 1 : 2}>
          <TextInput
            label='Phone number'
            placeholder='Phone number'
            {...form.getInputProps('rep.phone')}
          />
          <TextInput
            label='Email address'
            placeholder='Email address'
            {...form.getInputProps('rep.email')}
          />
        </SimpleGrid>
        <Space h={20} />
        <SimpleGrid>
          <TextInput
            label='Postal address'
            placeholder='Postal address'
            {...form.getInputProps('rep.postal.address')}
          />
        </SimpleGrid>
        <Space h={10} />
        <SimpleGrid cols={2}>
          <TextInput
            label='Suburb'
            placeholder='Suburb'
            {...form.getInputProps('rep.postal.suburb')}
          />
          <Flex direction='row' justify='space-between' gap='md'>
            <NativeSelect
              label='State'
              data={['--', 'ACT', 'NSW', 'NT', 'Qld', 'SA', 'Tas', 'Vic', 'WA']}
              {...form.getInputProps('rep.postal.state')}
            />
            <TextInput
              label='Postcode'
              placeholder='Postcode'
              {...form.getInputProps('rep.postal.postcode')}
            />
          </Flex>
        </SimpleGrid>
        <Space h={20} />
        <Radio.Group
          label='Can we SMS you?'
          {...form.getInputProps('rep.sms')}
        >
          <Group mt={5}>
            <Radio value='Yes' label='Yes' color='brand-teal' />
            <Radio value='No' label='No' color='brand-teal' />
          </Group>
        </Radio.Group>
        <Space h={20} />
        <Radio.Group
          label='Do you need an interpreter to help us communicate with you?'
          {...form.getInputProps('rep.interpreter')}
        >
          <Group>
            <Radio value='No' label='No' color='brand-teal' />
            <Group>
              <Radio value='Yes' label='Yes - provide language:' color='brand-teal' />
              <TextInput
                placeholder='Language'
                {...form.getInputProps('rep.int_language')}
              />
            </Group>
          </Group>
        </Radio.Group>
        <Title size='24px' fw={500} mt={30} mb={10} color='brand-teal'>Overview of Disability</Title>
        <SimpleGrid cols={isTablet ? 1 : 2}>
          <TextInput
            label='What is your primary disability?'
            placeholder='Primary disability'
            withAsterisk
            {...form.getInputProps('disability.primary')}
          />
        </SimpleGrid>
        <Space h={20} />
        <TextInput
          label='Do you have other disabilities? If so, please list below'
          placeholder='Other disabilities'
          {...form.getInputProps('disability.other')}
        />
        <Space h={10} />
        <Divider my="sm" color='gray.2' />
        <Radio.Group
          label='Was your disability caused by an accident or event?'
          withAsterisk
          {...form.getInputProps('disability.cause')}
        >
          <Radio value='No' label='No' color='brand-teal' />
          <Group>
            <Radio value='Yes' label='Yes - date of accident:' color='brand-teal' />
            <TextInput
              placeholder='DD/MM/YYYY'
              {...form.getInputProps('disability.date')}
            />
          </Group>
        </Radio.Group>
        <Space h={20} />
        <Radio.Group
          label='Has the compensation claim been finalised?'
          {...form.getInputProps('disability.compensation.finalised')}
        >
          <Group mt={5}>
            <Radio value='No' label='No' color='brand-teal' />
            <Radio value='Yes' label='Yes - provide details below' color='brand-teal' />
          </Group>
        </Radio.Group>
        <Space h={20} />
        <SimpleGrid cols={isTablet ? 1 : 2}>
          <TextInput
            label='What date was the claim finalised?'
            placeholder='DD/MM/YYYY'
            {...form.getInputProps('disability.compensation.date')}
          />
          <TextInput
            label='How much was the compensation payment?'
            placeholder='Compensation amount'
            {...form.getInputProps('disability.compensation.payment')}
          />
        </SimpleGrid>
        <Space h={10} />
        <Radio.Group
          label='Have you received legal advice about your compensation claim?'
          {...form.getInputProps('disability.compensation.legal')}
        >
          <Group mt={5}>
            <Radio value='No' label='No' color='brand-teal' />
            <Radio value='Yes' label='Yes' color='brand-teal' />
          </Group>
        </Radio.Group>
        <Title size='24px' fw={500} mt={30} mb={10} color='brand-teal'>General Practitioner's Information</Title>
        <SimpleGrid cols={isTablet ? 1 : 2}>
          <TextInput
            label="Doctor's Name"
            placeholder="Doctor's name"
            withAsterisk
            {...form.getInputProps('gp.name')}
          />
          <TextInput
            label='Phone number'
            placeholder='Phone number'
            withAsterisk
            {...form.getInputProps('gp.phone')}
          />
        </SimpleGrid>
        <Space h={20} />
        <SimpleGrid>
          <TextInput
            label='Address'
            placeholder='Address'
            withAsterisk
            {...form.getInputProps('gp.postal.address')}
          />
        </SimpleGrid>
        <Space h={10} />
        <SimpleGrid cols={2}>
          <TextInput
            label='Suburb'
            placeholder='Suburb'
            withAsterisk
            {...form.getInputProps('gp.postal.suburb')}
          />
          <Flex direction='row' justify='space-between' gap='md'>
            <NativeSelect
              label='State'
              data={['--', 'ACT', 'NSW', 'NT', 'Qld', 'SA', 'Tas', 'Vic', 'WA']}
              withAsterisk
              {...form.getInputProps('gp.postal.state')}
            />
            <TextInput
              label='Postcode'
              placeholder='Postcode'
              withAsterisk
              {...form.getInputProps('gp.postal.postcode')}
            />
          </Flex>
        </SimpleGrid>
        <Space h={20} />
        <Radio.Group
          label='Have you been a patient of your GP for over 6 months?'
          withAsterisk
          {...form.getInputProps('gp.patient_time')}
        >
          <Group mt={5}>
            <Radio value='Yes' label='Yes' color='brand-teal' />
            <Radio value='No' label='No' color='brand-teal' />
          </Group>
        </Radio.Group>
        <Space h={10} />
        <Divider mt='sm' mb='lg' color='gray.2' />
        <Flex justify='right'>
          <Button
            type='submit'
            color='brand-teal'
            leftIcon={<EnvelopeSimple size={16} />}
            loading={loading}
          >
            Submit Form
          </Button>
        </Flex>
      </form>
      <Modal
        opened={opened}
        onClose={handleClose}
        title={
          <Text fz='lg' weight='bold' color='brand-blue'>
            { sent ? 'Thank You' : 'Oops, Sorry' }
          </Text>
        }
        centered
        overlayProps={{
          color: '#3853A3',
          opacity: 0.4,
          blur: 3
        }}
      >
        {
          <Text fz='sm'>
            {
              sent
              ? 'Thanks for getting in touch with us. We\'ll do our best to reply to your message as soon as we can.'
              : 'Something seems to have gone wrong submitting your message. Please try again, or email us directly at admin@moreaboutthejourney.com.au'
            }
          </Text>
        }
      </Modal>
    </Flex>
  )
}

export default RegisterForm