import { Flex, Title, Text, Anchor, Divider } from '@mantine/core'

const NewSouthWales = () => {
  return (
    <>
      <Flex
        direction='column'
        gap={25}
        px={8}
      >
        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Companion Card</Title>
          <Text fz='sm'>
            <p>The NSW Companion Card program is for people with significant and permanent disability who need a high level of care in the community.</p>
            <strong>Phone:</strong> <Anchor href='tel:1800893044'>1800 893 044</Anchor><br />
            <strong>Email:</strong> <Anchor href='mailto:info.companioncard@facs.nsw.gov.au'>info.companioncard@facs.nsw.gov.au</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.nsw.gov.au/community-services/companion-card' target='_blank'>Companion Card</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />
        
        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Disability Parking Permits</Title>
          <Text fz='sm'>
            <p>If you have a temporary or permanent condition that affects your mobility, you may be eligible for the NSW Mobility Parking Scheme.</p>
            <strong>Phone:</strong> <Anchor href='tel:132213'>13 22 13</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.nsw.gov.au/driving-boating-and-transport/driver-and-rider-licences/health-conditions-and-disability/disability-parking-permits' target='_blank'>Disability parking permits in NSW</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Karitane Careline</Title>
          <Text fz='sm'>
            <p>For any parenting questions about children from birth to 5 years old.</p>
            <strong>Phone:</strong> <Anchor href='tel:1300227464'>1300 227 464</Anchor> or <Anchor href='tel:0297942350'>02 9794 2350</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://karitane.com.au/careline' target='_blank'>Karitane Careline</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />
        
        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Parent Line NSW</Title>
          <Text fz='sm'>
            <p>A free telephone counselling and support service for parents and carers with children from birth to 18 who live in NSW.</p>
            <strong>Phone:</strong> <Anchor href='tel:1300130052'>1300 130 052</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.parentline.org.au/' target='_blank'>Parent Line NSW</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />
        
        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Tresillian's Parent's Help Line</Title>
          <Text fz='sm'>
            <p>The Nurses on Tresillian's Parent's Help Line can help with any questions you have about your baby.</p>
            <strong>Phone:</strong> <Anchor href='tel:1300272736'>1300 272 736</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.tresillian.org.au/services/parents-help-line/' target='_blank'>Parent's Help Line</Anchor>
          </Text>
        </Flex>
      </Flex>
    </>
  )
}

export default NewSouthWales