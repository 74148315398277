import { Flex, Title, SimpleGrid, Text } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

const Testimonials = () => {
  const isMaxWidth = useMediaQuery('(min-width: 1000px)')
  const isMobile = useMediaQuery('(max-width: 550px)')

  return (
    <Flex
      direction='column'
      justify='center'
      align='center'
      w={ isMaxWidth ? 1000 : '95vw' }
      mx='auto'
      mb={80}
      >
      <Title size={ isMobile ? '36px' : '44px' } fw={600} mt={50} mb={30} ta='center' color='brand-teal'>Testimonials</Title>
      <SimpleGrid cols={ isMobile ? 1 : 2 } spacing={50}>
        <Flex
          direction='column'
          justify='center'
          align='center'
          maw={400}
          >
          <Text fz='sm' ta='center'>
            Thank you to Jacinta for everything you have done for me, making sure all of my needs are met. I am thankful to everyone who has helped me. I look forward to getting the powered wheelchair.
          </Text>
          <Text fz='sm' mt='sm' fw={500} color='brand-blue'>Carrie</Text>
        </Flex>
        <Flex
          direction='column'
          justify='center'
          align='center'
          maw={400}
          >
          <Text fz='sm' ta='center'>
            Michelle, I was just sitting here thinking about everything that I need to do before I go into hospital and I had the realisation that I haven't got any worries, because I have absolute trust in you and you always amaze me how you get things done so quickly, you're the GOAT, which is pretty amazing.
          </Text>
          <Text fz='sm' mt='sm' fw={500} color='brand-blue'>Linda</Text>
        </Flex>
      </SimpleGrid>
    </Flex>
  )
}

export default Testimonials