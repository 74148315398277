import { Flex, Title, Text, Anchor, Divider } from '@mantine/core'

const Queensland = () => {
  return (
    <>
      <Flex
        direction='column'
        gap={25}
        px={8}
      >
        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>13 HEALTH</Title>
          <Text fz='sm'>
            <p>13 HEALTH is a confidential phone service that provides health advice to Queenslanders.</p>
            <strong>Phone:</strong> <Anchor href='tel:13432584'>13 43 25 84</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.qld.gov.au/health/contacts/advice/13health' target='_blank'>13 HEALTH</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />
        
        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Companion Card</Title>
          <Text fz='sm'>
            <p>Companion card holders can receive a second companion ticket at no extra charge at participating venues and on public transport.</p>
            <strong>Website:</strong> <Anchor href='https://www.qld.gov.au/disability/out-and-about/subsidies-concessions-passes/companion-card' target='_blank'>Companion Card</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Disability Parking Permits</Title>
          <Text fz='sm'>
            <p>The disability parking permit scheme allows permit holders to park in bays showing the international symbol of access.</p>
            <strong>Website:</strong> <Anchor href='https://www.qld.gov.au/disability/out-and-about/travel-transport/driving/parking-permits' target='_blank'>Parking permits</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Parentline</Title>
          <Text fz='sm'>
            <p>Provides free counselling and support for parents and carers in Queensland and the Northern Territory.</p>
            <strong>Phone:</strong> <Anchor href='tel:1300301300'>1300 301 300</Anchor><br />
            <strong>Email:</strong> <Anchor href='mailto:counsellor@parentline.com.au'>counsellor@parentline.com.au</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://parentline.com.au/' target='_blank'>Parentline</Anchor>
          </Text>
        </Flex>

        <Divider color='brand-teal' w='75%' mx='auto' />

        <Flex direction='column' gap={5}>
          <Title size='md' color='brand-darkblue'>Queensland Savers</Title>
          <Text fz='sm'>
            <p>Queensland Savers is a government-funded initiative to help provide savings and support services to those in need.</p>
            <strong>Phone:</strong> <Anchor href='tel:137468'>13 74 68</Anchor><br />
            <strong>Website:</strong> <Anchor href='https://www.queenslandsavers.qld.gov.au/' target='_blank'>Queensland Savers</Anchor>
          </Text>
        </Flex>
      </Flex>
    </>
  )
}

export default Queensland