import { Helmet } from 'react-helmet'
import { Flex, Text, Space } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

import Header from '../components/Header'
import Footer from '../components/Footer'
import PageHeading from '../components/PageHeading'
import RegisterForm from '../components/RegisterForm'

const AccessApplications = () => {
  const isMaxWidth = useMediaQuery('(min-width: 800px)')
  const isMobile = useMediaQuery('(max-width: 800px)')

  return (
    <>
      <Helmet>
        <title>Access Applications | More About the Journey</title>
        <meta name='description' content='We work with you to apply for the National Disability Insurance Scheme, and make sure you get everything you are entitled to.' />

        <meta property='og:title' content='Access Applications' />
        <meta property='og:description' content='We work with you to apply for the National Disability Insurance Scheme, and make sure you get everything you are entitled to.' />
        <meta property='og:url' content='https://moreaboutthejourney.com.au/services/access-applications' />

        <meta name='twitter:title' content='Access Applications | More About the Journey' />
        <meta name='twitter:description' content='We work with you to apply for the National Disability Insurance Scheme, and make sure you get everything you are entitled to.' />
      </Helmet>

      <Header />
      <PageHeading title='Access Applications' />
      <Flex
        direction='column'
        justify='left'
        w={ isMaxWidth ? 800 : '90vw' }
        mx='auto'
        mt='xl'
        mb={80}
      >
        <Text fz={ isMobile ? 'sm' : 'md' }>
          Supporting someone to apply for the NDIS requires a compassionate and comprehensive approach to ensure their needs are effectively addressed. At More About the Journey, our role is to guide and assist individuals and their support networks throughout the application process, making it as smooth and accessible as possible.
        </Text>
        <Space h={20} />
        <Text fz={ isMobile ? 'sm' : 'md' }>
          We will work closely with the individual, their family, and relevant professionals to gather all necessary information, including medical reports, assessments, and supporting documentation. By carefully understanding the unique circumstances, goals, and challenges, we can help them articulate their needs and aspirations in a clear and compelling manner.
        </Text>
        <Space h={20} />
        <Text fz={ isMobile ? 'sm' : 'md' }>
          Through our expertise and knowledge of the NDIS eligibility criteria, we will navigate the application system, advocating on their behalf and ensuring that they receive the appropriate level of support. Our aim is to empower them throughout this process, providing the necessary guidance and reassurance so that they can access the resources and services they require to lead a fulfilling and independent life.
        </Text>
        <RegisterForm />
      </Flex>
      <Footer />
    </>
  )
}

export default AccessApplications