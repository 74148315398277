import { Helmet } from 'react-helmet'
import { Flex, Text, Box, BackgroundImage, Space } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

import Header from '../components/Header'
import Footer from '../components/Footer'
import PageHeading from '../components/PageHeading'
import { Quote } from '../theme'

import HandsTogether from '../assets/images/hands-together.jpg'

const About = () => {
  const isMaxWidth = useMediaQuery('(min-width: 1000px)')
  const isTablet = useMediaQuery('(max-width: 1000px)')
  const isMobile = useMediaQuery('(max-width: 800px)')

  return (
    <>
      <Helmet>
        <title>About Us | More About the Journey</title>
        <meta name='description' content='Learn about what we do at More About the Journey, and our goals when it comes to helping you.' />

        <meta property='og:title' content='About Us' />
        <meta property='og:description' content='Learn about what we do at More About the Journey, and our goals when it comes to helping you.' />
        <meta property='og:url' content='https://moreaboutthejourney.com.au/about' />

        <meta name='twitter:title' content='About Us | More About the Journey' />
        <meta name='twitter:description' content='Learn about what we do at More About the Journey, and our goals when it comes to helping you.' />
      </Helmet>

      <Header />
      <PageHeading title='About Us' />
      <Flex
        direction='column'
        justify='left'
        w={ isMaxWidth ? 1000 : '90vw' }
        mx='auto'
        mt='xl'
        mb={80}
      >
        <Flex
          direction={isMobile ? 'column' : 'row'}
          justify='center'
          gap={40}
        >
          <Flex
            direction='column'
            gap='sm'
          >
            <Text fz={ isMobile ? 'sm' : 'md' }>
              We are a team of Support Coordinators with strong experience in the disability sector, disability services, and an in-depth understanding of the NDIS.
            </Text>
            <Text fz={ isMobile ? 'sm' : 'md' }>
              We believe
              <ul>
                <li>The <strong>journey</strong> is what makes us, and it adds <strong>value</strong> to our life</li>
                <li>The journey presents <strong>lessons</strong> from both <strong>challenges</strong> and <strong>opportunities</strong></li>
                <li>The <strong>best memories</strong> comes from working towards <strong>your goals</strong></li>
              </ul>
            </Text>
            <Text fz={ isMobile ? 'sm' : 'md' }>
              At <i><strong>More About the Journey</strong></i>, we believe in learning to enjoy the <i>"now"</i>, and focusing on what you can be grateful for and control in the present. We often struggle to take a step back and look at how far we've come, rather than always striving to do more or to get to the next step. Learning to enjoy the journey is all about being grateful for what you have in the present, and recognising how far you've come on your journey at the present moment in time.
            </Text>
            <Text fz={ isMobile ? 'sm' : 'md' }>
              Let us come on the <strong>journey</strong> with you.
            </Text>
          </Flex>
          <Box>
            <BackgroundImage
              src={HandsTogether}
              radius={10}
            >
              <Space w={isTablet ? 400 : 500} h={isTablet ? 450 : 550} />
            </BackgroundImage>
          </Box>
        </Flex>
        <Quote>
          A journey takes time, and the lessons we learn best, they come from the journey, not the destination.
        </Quote>
      </Flex>
      <Footer />
    </>
  )
}

export default About