import { Helmet } from 'react-helmet'
import { Flex, Text, Space, Anchor, SimpleGrid, Accordion, Title } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

import Header from '../components/Header'
import Footer from '../components/Footer'
import PageHeading from '../components/PageHeading'

import Australia from '../components/Resources/Australia'
import Victoria from '../components/Resources/Victoria'
import NewSouthWales from '../components/Resources/NewSouthWales'
import AustralianCapitalTerritory from '../components/Resources/AustralianCapitalTerritory'
import Queensland from '../components/Resources/Queensland'
import Tasmania from '../components/Resources/Tasmania'
import SouthAustralia from '../components/Resources/SouthAustralia'
import WesternAustralia from '../components/Resources/WesternAustralia'
import NorthernTerritory from '../components/Resources/NorthernTerritory'

const Resources = () => {
  const isMaxWidth = useMediaQuery('(min-width: 1000px)')
  const isTablet = useMediaQuery('(max-width: 1000px)')
  const isMobile = useMediaQuery('(max-width: 800px)')

  return (
    <>
      <Helmet>
        <title>Resources | More About the Journey</title>
        <meta name='description' content='Find resources nationwide for NDIS participants and their families.' />

        <meta property='og:title' content='Resources' />
        <meta property='og:description' content='Find resources nationwide for NDIS participants and their families.' />
        <meta property='og:url' content='https://moreaboutthejourney.com.au/resources' />

        <meta name='twitter:title' content='Resources | More About the Journey' />
        <meta name='twitter:description' content='Find resources nationwide for NDIS participants and their families.' />
      </Helmet>

      <Header />
      <PageHeading title='Resources' />
      <Flex
        direction='column'
        justify='left'
        w={ isMaxWidth ? 1000 : '90vw' }
        mx='auto'
        mt='xl'
        mb={80}
      >
        <Text fz={ isMobile ? 'sm' : 'md' }>
          This page provides information about key services available across Australia for those with a disabilty, their families, and their carers. This list will be updated periodically, but if you know of any services not listed here, or you have any questions about what services you may qualify for, feel free to <Anchor href='contact'>contact us</Anchor>.
        </Text>
        <Space h={40} />
        <SimpleGrid cols={ isMobile ? 1 : 2 } spacing={1}>
          <Accordion
            radius='md'
            multiple={true}
            w='95%'
            mx='auto'
          >
            <Accordion.Item value='aus'>
              <Accordion.Control><Title size='18px' color='brand-blue' my='xs'>Australia Wide</Title></Accordion.Control>
              <Accordion.Panel>
                <Australia />
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value='vic'>
              <Accordion.Control><Title size='18px' color='brand-blue' my='xs'>Victoria</Title></Accordion.Control>
              <Accordion.Panel>
                <Victoria />
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value='nsw'>
              <Accordion.Control><Title size='18px' color='brand-blue' my='xs'>New South Wales</Title></Accordion.Control>
              <Accordion.Panel>
                <NewSouthWales />
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value='act'>
              <Accordion.Control><Title size='18px' color='brand-blue' my='xs'>Australian Capital Territory</Title></Accordion.Control>
              <Accordion.Panel>
                <AustralianCapitalTerritory />
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value='qld'>
              <Accordion.Control><Title size='18px' color='brand-blue' my='xs'>Queensland</Title></Accordion.Control>
              <Accordion.Panel>
                <Queensland />
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>

          <Accordion
            radius='md'
            multiple={true}
            w='95%'
            mx='auto'
          >
            <Accordion.Item value='tas'>
              <Accordion.Control><Title size='18px' color='brand-blue' my='xs'>Tasmania</Title></Accordion.Control>
              <Accordion.Panel>
                <Tasmania />
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value='sa'>
              <Accordion.Control><Title size='18px' color='brand-blue' my='xs'>South Australia</Title></Accordion.Control>
              <Accordion.Panel>
                <SouthAustralia />
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value='wa'>
              <Accordion.Control><Title size='18px' color='brand-blue' my='xs'>Western Australia</Title></Accordion.Control>
              <Accordion.Panel>
                <WesternAustralia />
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value='nt'>
              <Accordion.Control><Title size='18px' color='brand-blue' my='xs'>Northern Territory</Title></Accordion.Control>
              <Accordion.Panel>
                <NorthernTerritory />
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </SimpleGrid>
      </Flex>
      <Footer />
    </>
  )
}

export default Resources