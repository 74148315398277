import { useState } from 'react'
import axios from 'axios'
import { Flex, Title, SimpleGrid, NativeSelect, TextInput, Space, Divider, Radio, Group, Checkbox, Anchor, Button, Modal, Text } from '@mantine/core'
import { useMediaQuery, useDisclosure } from '@mantine/hooks'
import { useForm, hasLength, isEmail, isNotEmpty, matches } from '@mantine/form'
import { EnvelopeSimple } from '@phosphor-icons/react'

interface FormValues {
  name: {
    given: string,
    surname: string
  },
  dob: string,
  ndis_no: string,
  home: {
    address: string,
    suburb: string,
    state: string,
    postcode: string
  },
  phone: string,
  email: string,
  rep: {
    name: string,
    surname: string,
    phone: string,
    email: string,
    nominee: string
  },
  service: boolean,
  audit: boolean
}

const SupportForm = () => {
  const isTablet = useMediaQuery('(max-width: 450px)')

  const [opened, { open, close }] = useDisclosure(false)
  const [modal, setModal] = useState(close)
  const [sent, setSent] = useState(false)
  const [loading, setLoading] = useState(false)

  const form = useForm({
    initialValues: {
      name: {
        given: '',
        surname: ''
      },
      dob: '',
      ndis_no: '',
      home: {
        address: '',
        suburb: '',
        state: '',
        postcode: ''
      },
      phone: '',
      email: '',
      rep: {
        name: '',
        surname: '',
        phone: '',
        email: '',
        nominee: ''
      },
      service: false,
      audit: false
    },
    validate: {
      name: {
        given: hasLength({ min: 2 }, 'Name must contain at least 2 characters'),
        surname: hasLength({ min: 2 }, 'Surname must contain at least 2 characters')
      },
      dob: matches(/\d{1,2}\/\d{1,2}\/(\d{4}|\d{2})/, 'Please use the format DD/MM/YYYY'),
      ndis_no: matches(/\d{9}/, 'NDIS numbers are 9 digits long'),
      home: {
        address: isNotEmpty('Please include your home address'),
        suburb: isNotEmpty('Please include your suburb'),
        state: matches(/[A-Z,a-z]{1,}/, 'Please select a state'),
        postcode: matches(/\d{4}/, 'Please use a valid postcode')
      },
      phone: matches(/\d{10}|\d{8}/, 'Phone number must be either 8 or 10 digits long'),
      service: isNotEmpty('You must read and agree to the service agreement')
    }
  })

  const handleSubmit = (values: FormValues) => {
    setLoading(true)

    const body = `
      Form submission from More About the Journey's <b>Support Coordination/Specialist Support Coordination</b> page:<br /><br />

      <span style="font-weight:600;font-size:150%">${values.name.given} ${values.name.surname}</span><br /><br />
      
      --<br /><br />
  
      <b><u>Applicant's Information</u></b><br /><br />
      <b>Given Name/s:</b><br />${values.name.given}<br /><br />
      <b>Surname:</b><br />${values.name.surname}<br /><br />
      <b>Date of birth:</b><br />${values.dob}<br /><br />
      <b>NDIS number:</b><br />${values.ndis_no}<br /><br />
      <b>Home address:</b><br />${values.home.address}, ${values.home.suburb} ${values.home.state} ${values.home.postcode}<br /><br />
      <b>Phone number:</b><br />${values.phone}<br /><br />
      <b>Email address:</b><br />${values.email}<br /><br /><br />

      <b><u>Representative's Information</u> (if applicable)</b><br /><br />
      <b>First name/s:</b><br />${values.rep.name}<br /><br />
      <b>Surname:</b><br />${values.rep.surname}<br /><br />
      <b>Phone number:</b><br />${values.rep.phone}<br /><br />
      <b>Email address:</b><br />${values.rep.email}<br /><br />
      <b>Nominee type:</b><br />${values.rep.nominee}<br /><br /><br />

      <b><u>Agreements</u></b><br /><br />
      <b>Accepted the service agreement:</b><br />${(values.service ? 'Yes' : 'No')}<br /><br />
      <b>Agreed to have files reviewed in future audits:</b><br />${(values.audit ? 'Yes' : 'No')}<br /><br />
    `

    axios.get('https://api.elasticemail.com/v2/email/send', {
      params: {
        apikey: process.env.REACT_APP_EMAIL_API_KEY,
        subject: 'Support Coordination Request Form: ' + values.name.given + ' ' + values.name.surname,
        from: process.env.REACT_APP_EMAIL_USER,
        fromName: 'More About the Journey Website',
        to: process.env.REACT_APP_EMAIL_USER,
        bodyHtml: body,
        replyTo: values.email,
        headers: { 'Access-Control-Allow-Origin': '*' }
      }
    })
    .then((response) => {
      // console.log(response)
      setSent(true)
      setModal(open)
    })
    .catch((error) => {
      console.log(error)
      setSent(false)
      setModal(open)
    })
  }

  const handleClose = () => {
    setLoading(false)
    setModal(close)
  }

  return (
    <>
      <Flex direction='column' w={isTablet ? '90vw' : 600} mx='auto'>
        <Title size='36px' fw={500} mt={50} mb={20} ta='center' color='brand-blue'>Support Request Form</Title>
        <form onSubmit={form.onSubmit((values) => {handleSubmit(values)})}>
          <Title size='24px' fw={500} mb={10} color='brand-teal'>Applicant's Information</Title>
          <SimpleGrid cols={isTablet ? 1 : 2}>
            <TextInput
              label='Given name/s'
              placeholder='Given name/s'
              withAsterisk
              {...form.getInputProps('name.given')}
            />
            <TextInput
              label='Surname'
              placeholder='Surname'
              withAsterisk
              {...form.getInputProps('name.surname')}
            />
          </SimpleGrid>
          <Space h={10} />
          <SimpleGrid cols={isTablet ? 1 : 2}>
            <TextInput
              label='Date of birth'
              placeholder='DD/MM/YYYY'
              withAsterisk
              {...form.getInputProps('dob')}
            />
            <TextInput
              label='NDIS number'
              placeholder='NDIS number'
              withAsterisk
              {...form.getInputProps('ndis_no')}
            />
          </SimpleGrid>
          <Space h={10} />
          <Divider my="sm" color='gray.2' />
          <SimpleGrid>
            <TextInput
              label='Current address'
              placeholder='Current address'
              withAsterisk
              {...form.getInputProps('home.address')}
            />
          </SimpleGrid>
          <Space h={10} />
          <SimpleGrid cols={2}>
            <TextInput
              label='Suburb'
              placeholder='Suburb'
              withAsterisk
              {...form.getInputProps('home.suburb')}
            />
            <Flex direction='row' justify='space-between' gap='md'>
              <NativeSelect
                label='State'
                data={['--', 'ACT', 'NSW', 'NT', 'Qld', 'SA', 'Tas', 'Vic', 'WA']}
                withAsterisk
                {...form.getInputProps('home.state')}
              />
              <TextInput
                label='Postcode'
                placeholder='Postcode'
                withAsterisk
                {...form.getInputProps('home.postcode')}
              />
            </Flex>
          </SimpleGrid>
          <Space h={10} />
          <Divider my="sm" color='gray.2' />
          <SimpleGrid cols={isTablet ? 1 : 2}>
            <TextInput
              label='Phone number'
              placeholder='Phone number'
              withAsterisk
              {...form.getInputProps('phone')}
            />
            <TextInput
              label='Email address'
              placeholder='Email address'
              {...form.getInputProps('email')}
            />
          </SimpleGrid>
          <Title size='24px' fw={500} mt={30} mb={10} color='brand-teal'>Representative's Information (if applicable)</Title>
          <SimpleGrid cols={isTablet ? 1 : 2}>
            <TextInput
              label='First name/s'
              placeholder='First name/s'
              {...form.getInputProps('rep.name')}
            />
            <TextInput
              label='Surname'
              placeholder='Surname'
              {...form.getInputProps('rep.surname')}
            />
          </SimpleGrid>
          <Space h={10} />
          <SimpleGrid cols={isTablet ? 1 : 2}>
          <TextInput
            label='Phone number'
            placeholder='Phone number'
            {...form.getInputProps('rep.phone')}
          />
          <TextInput
            label='Email address'
            placeholder='Email address'
            {...form.getInputProps('rep.email')}
          />
          </SimpleGrid>
          <Space h={20} />
          <Radio.Group
            label='Are you the NDIS Nominee:'
            {...form.getInputProps('rep.nominee')}
          >
            <Group mt={5}>
              <Radio value='plan' label='Plan Nominee' color='brand-teal' />
              <Radio value='correspondence' label='Correspondence Nominee' color='brand-teal' />
              <Radio value='primary' label='Primary Contact' color='brand-teal' />
              <Radio value='other' label='Other' color='brand-teal' />
            </Group>
          </Radio.Group>
          <Space h={10} />
          <Divider mt='sm' mb='lg' color='gray.2' />
          <Checkbox
            label={
              <>
                I accept the{' '}
                <Anchor href='../service-agreement' target='_blank'>
                  service agreement
                </Anchor>
              </>
            }
            color='brand-teal'
            {...form.getInputProps('service')}
          />
          <Space h={10} />
          <Checkbox
            label='I agree to have my NDIS files reviewed as part of any future audit, as outlined in the service agreement'
            color='brand-teal'
            {...form.getInputProps('audit')}
          />
          <Space h={10} />
          <Divider mt='sm' mb='lg' color='gray.2' />
          <Flex justify='right'>
            <Button
              type='submit'
              color='brand-teal'
              leftIcon={<EnvelopeSimple size={16} />}
              loading={loading}
            >
              Submit Form
            </Button>
          </Flex>
        </form>
        <Modal
          opened={opened}
          onClose={handleClose}
          title={
            <Text fz='lg' weight='bold' color='brand-blue'>
              { sent ? 'Thank You' : 'Oops, Sorry' }
            </Text>
          }
          centered
          overlayProps={{
            color: '#3853A3',
            opacity: 0.4,
            blur: 3
          }}
        >
          {
            <Text fz='sm'>
              {
                sent
                ? 'Thanks for getting in touch with us. We\'ll do our best to reply to your message as soon as we can.'
                : 'Something seems to have gone wrong submitting your message. Please try again, or email us directly at admin@moreaboutthejourney.com.au'
              }
            </Text>
          }
        </Modal>
      </Flex>
    </>
  )
}

export default SupportForm