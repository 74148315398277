import { Flex, SimpleGrid, Title, Text, Button } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { Files, Users, UsersThree, Heartbeat, ArrowCircleRight } from '@phosphor-icons/react'

const OurServices = () => {
  const isMaxWidth = useMediaQuery('(min-width: 1000px)')
  const isMobile = useMediaQuery('(max-width: 550px)')
  const isTablet = useMediaQuery('(max-width: 800px)')

  return (
    <Flex
      direction='column'
      justify='center'
      align='center'
      w={ isMaxWidth ? 1000 : '95vw' }
      mx='auto'
      mt={20}
      mb={60}
    >
      <Title size={ isMobile ? '36px' : '44px' } fw={600} mt={30} mb={10} ta='center' color='brand-teal'>Our Services</Title>
      <SimpleGrid cols={ isTablet ? (isMobile ? 1 : 2) : 4 } spacing='0'>
        <Flex
          direction='column'
          justify='center'
          align='center'
          m='lg'
          maw={300}
          >
          <Files size={100} weight='duotone' color='#3ABADF' />
          <Title order={3} mt={10} ta='center' color='brand-blue'>Access<br />Applications</Title>
          <Text fz='sm' ta='center' my={15}>
            We can help walk you through the application process, and answer any questions you might have.
          </Text>
          <Button
            component='a'
            href='/services/access-applications'
            variant='subtle'
            color='brand-purple'
            rightIcon={<ArrowCircleRight size={20} weight='duotone' />}
            >
            Find Out More</Button>
        </Flex>
        <Flex
          direction='column'
          justify='center'
          align='center'
          m='xl'
          maw={300}
          >
            <Users size={100} weight='duotone' color='#3ABADF' />
          <Title  order={3} mt={10} ta='center' color='brand-blue'>Support Coordination</Title>
          <Text fz='sm' ta='center' my={15}>
            We work closely with NDIS participants and their families and carers to get the most out of their NDIS plan.
          </Text>
          <Button
            component='a'
            href='/services/support-coordination'
            variant='subtle'
            color='brand-purple'
            rightIcon={<ArrowCircleRight size={20} weight='duotone' />}
            >
            Find Out More</Button>
        </Flex>
        <Flex
          direction='column'
          justify='center'
          align='center'
          m='xl'
          maw={300}
          >
            <UsersThree size={100} weight='duotone' color='#3ABADF' />
          <Title  order={3} mt={10} ta='center' color='brand-blue'>Specialist Support Coordination</Title>
          <Text fz='sm' ta='center' my={15}>
            We work with NDIS participants dealing with high-level risks in their current situation.
          </Text>
          <Button
            component='a'
            href='/services/specialist-support-coordination'
            variant='subtle'
            color='brand-purple'
            rightIcon={<ArrowCircleRight size={20} weight='duotone' />}
            >
            Find Out More</Button>
        </Flex>
        <Flex
          direction='column'
          justify='center'
          align='center'
          m='xl'
          maw={300}
          >
          <Heartbeat size={100} weight='duotone' color='#3ABADF' />
          <Title  order={3} mt={10} ta='center' color='brand-blue'>Psychosocial Recovery Coaching</Title>
          <Text fz='sm' ta='center' my={15}>
            We can help you work through life's challenges, and assist you in making the right choices for you.
          </Text>
          <Button
            component='a'
            href='/services/psychosocial-recovery-coaching'
            variant='subtle'
            color='brand-purple'
            rightIcon={<ArrowCircleRight size={20} weight='duotone' />}
            >
            Find Out More</Button>
        </Flex>
      </SimpleGrid>
    </Flex>
  )
}

export default OurServices